import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Image,
  InputGroup,
  Spinner,
  Card,
} from "react-bootstrap";
import "./LoginPage.css";

import { Link, useHistory, withRouter } from "react-router-dom";
import ModalLogin1 from "../../components/Modals/LoginPageModals/ModalLogin1";
import LoginSideBar from "../../components/SideBar/LoginSideBar/LoginSideBar";
import DeskFooterLogin from "../../components/Footer/DeskFooter/DeskFooterLogin";
import MobFooter from "../../components/Footer/MobFooter/MobFooter";
import Logo from "../../components/Logo/Logo";
import GMDlogo from "../../components/Logo/GMDlogo";
import { handlePostPayer} from "../../functions/LoginPage/LoginPageFuncs";
import ErrorToast from "../../components/Toasts/ErrorToast";
import GMDwithoutDD from "../../components/Logo/GMDwithoutDD";
import PlanList2 from "../../components/PlansList/PlanList2";
import LoginPlanList from "../../components/PlansList/LoginPlanList";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { fetchActivePayerList, fetchHealthPlanList } from "../../apis/apis";
import { UIActions } from "../../redux/feature/UISlice";
import { searchItems } from "../../functions/SearchFuncs/SearchFuncs";

//@ts-ignore
const LoginPayerPage = ({ clientName, clientLogo, linkTo, oauth_link }) => {
  // window.location.reload()
  let history = useHistory()
  const storedTheme = sessionStorage.getItem("theme");

  const clientLocal = sessionStorage.getItem("client")
  const path = window.location.pathname
  const path2 = path.split('/')[2]
  console.log("path", path2);
  const [logologin, setLogologin] = useState("")

  const [hmnyClient, sethmnyClient] = useState(false);

  const [iCareclient, setICaeclient] = useState(false);

  const [spinner, setSpinner] = useState(true);

  const [btndisable, setBtndisable] = useState(true);

  const [showToast, setShowToast] = useState(false)

  const formSubmitHandler = (e: any) => {
    e.preventDefault();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const showSpinnerTrue = () => setSpinner(true)
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [payerStatus, setPayerStatus] = useState(false)
  const [planStatus, setPlanStatus] = useState(false)
  const [client_name, setClient_Name] = useState("")
  const [payerPlanList, setPayerPlanList] = useState([])
  const [allPayersList, setAllPayersList] = useState([])

  var backArrow = "<--";

      const dispatch = useAppDispatch()
      const UI = useAppSelector((state) => state.UI)
      const fetchNewPayers = async () => {
        try {
          const res = await fetchActivePayerList()
              const AllPayerList = res.data
              dispatch(UIActions.updatePayers(AllPayerList))
              localStorage.setItem("payers_list", JSON.stringify(AllPayerList))
              setAllPayersList(AllPayerList)
              setPayerStatus(true)
          }
          catch (err) {
              console.log(err)
          }
      }
      const fetchAllPLansList = async () => {
        try{
            const res = await fetchHealthPlanList()
            const sortedPlansList = res.data.sort((a: { plans: string | any[] }, b: { plans: string | any[] }) => {
                if (a.plans.length < b.plans.length) {
                    return 1
                }
                else if (a.plans.length > b.plans.length) {
                    return -1
                }
                else {
                    return 0
                }
            })
            sessionStorage.setItem("plans_list", JSON.stringify(sortedPlansList))            
            dispatch(UIActions.updatePlansList(sortedPlansList));
            setPlanStatus(true)
            setPayerPlanList(sortedPlansList)
    }
    catch (err) {
        console.log(err)
    }
    }
  useEffect(() => {
    if(payerPlanList.length === 0){
      fetchAllPLansList()
    }
    if(allPayersList.length ===0){
      fetchNewPayers()
    }
    console.log(allPayersList,"==");
    
  }, []);

  useEffect(() => {
    if(path2 == "hmny"){
      sethmnyClient(true)
      
    }
  },[window.location.pathname])
  useEffect(() => {
    if(payerStatus === true && planStatus === true){
      allPayersList.map((val: any,i: any)=>{
        if(val.slug === path2){
          setClient_Name(val.name)
          sessionStorage.setItem("client",val.name)
          console.log(val.name);
          sessionStorage.setItem("logo",`${process.env.REACT_APP_LOGO}/${val.clientlogo}`)
        }
        else if(val.slug.startsWith("hmny") && path2=="hmny"){
          setClient_Name(val.name)
          sessionStorage.setItem("client",val.name)
          console.log(val.name);
          sessionStorage.setItem("logo",`${process.env.REACT_APP_LOGO}/${val.clientlogo}`)
        }
      })
  }
  }, [payerStatus,planStatus])

  console.log(payerPlanList,"--")
  return (
    <Container fluid className={storedTheme != 'dark' ? "page-wrapper-login" : "page-wrapper-login page-wrapper-login_dark"}>
      <Row className={storedTheme != 'dark' ? "content-wrapper-login" : "content-wrapper-login page-wrapper_dark"}>
        <Col lg={4} className={storedTheme != 'dark' ? "left-bar-login" : "left-bar-login left-bar-login_dark"}>
          <Row>
            <Col className="other_gmd_logo">
              <GMDwithoutDD />
            </Col>
            <Col className="back_to_landing">
              <span onClick={() => history.goBack()} className='back_text'>{backArrow}Back</span>
            </Col>
          </Row>
          <LoginSideBar />
          <DeskFooterLogin />
        </Col>
        <Col lg={8} xs={12} sm={12} className={storedTheme !== 'dark' ? "right-bar-login" : "right-bar-login right_bar_dark"}>
        {payerPlanList.length === 0?
      <Spinner animation="border" variant="primary"/>
      :
      <>
          <Logo />
          {!hmnyClient &&
          <>
            <Form
              className="form-complete-login"
              onSubmit={formSubmitHandler}
              data-testid="form"
            >
              <Row className="search_login_row">
                <Col>
                  <InputGroup className="mb-3 search_bar_login">

                    <Form.Control
                      placeholder="Search here By Health Plan Name, Payer name or HNo.."
                      aria-label="payer"
                      aria-describedby="basic-addon1"
                      onChange={e => {
                        searchItems(e.target.value, setSearchInput, payerPlanList, setFilteredResults," ");
                      }}
                      className={storedTheme != 'dark' ? "search_input_box" : 'search_input_box search_input_box_dark'}
                      type='search'
                    />

                  </InputGroup>
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    type="submit"
                    className={storedTheme != 'dark' ? "btn-login" : "btn-login btn-login_dark"}
                    onClick={() => {
                      handleShow();
                      handlePostPayer(client_name, setSpinner, setBtndisable, setShowToast, setShow);
                    }}
                    data-testid="btn_login"
                  >
                    Login with member ID and Password
                  </Button>
                </Col>
              </Row>


            </Form>
            <span className="planlisted_txt">Plans Listed under {client_name}</span>
            <hr />
            <div className="login_plan_List">
              {searchInput.length > 0 ?
                <>
                  {filteredResults.length > 0 ?
                    <LoginPlanList filteredItems={filteredResults} client_name={client_name} />
                    :
                    <p>No results found</p>
                  }
                </>
                :
                <LoginPlanList filteredItems={payerPlanList} client_name={client_name} />
              }
            </div>
            </>
          }
          {hmnyClient &&
          <>
          
          <div className="login_plan_hmny_List">
          <span className="planlisted_txt">Plans Listed under HMNY</span>
            <hr />
          <LoginPlanList filteredItems={payerPlanList} client_name={"Hmny"} />
          </div>
          </>
          }
          

          <ModalLogin1
            show={show}
            handleClose={handleClose}
            oauth_link={oauth_link}
            clientName={client_name}
            spinner={spinner}
            btndisable={btndisable}
            showSpinnerTrue={showSpinnerTrue} />
      </>
}
        </Col>
      </Row>
      <MobFooter />
      {showToast &&
        <ErrorToast show={showToast} setShowToast={setShowToast} />
      }
    </Container>
  );
};
//@ts-ignore
export default withRouter(LoginPayerPage);
