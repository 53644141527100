import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import './PageNotFound.scss'
import { useHistory } from 'react-router'
import LandingPageFooter from '../../components/Footer/LandingPageFooter/LandingPageFooter'

const PageNotFound = () => {
  const history = useHistory();

  const handleGoToHome = () => {
    history.push("/");
  }
  return (
    <div className='content_wrapper'>
      <section className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">404</h1>


                </div>

                <div className="contant_box_404">
                  <h3 className="h2">
                    Look like you're lost
                  </h3>

                  <p>The page you are looking for is not avaible!</p>

                  <Button variant='primary' onClick={handleGoToHome}>Go Back</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className='footer_404'>
        <LandingPageFooter />
      </footer>
    </div>
  )
}

export default PageNotFound