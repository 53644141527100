import React from 'react'
import { Image } from 'react-bootstrap';
import memberPortalLogo from "../../images/GMDlogo.svg";
import mmhd_logo from "../../images/mmhdWhiteT.png";

import './MMHDlogolanding.scss'
const MMHDlogolanding = () => {
    return (
        <div>
            <div className="member-portal-login-MMHD">
                {/* <img
                    src={memberPortalLogo}
                    alt="membpLogo"
                    data-testid="GMD_Logo"
                    className='memp_logo_landing'
                /> */}
                {/* <span className="logo-login-MMHD"><i>Move</i>My<i>HealthData</i></span> */}
                <Image src={mmhd_logo} alt='mmhdlogo' className='mmhd_logo_dashboard' />
            </div>
        </div>
    )
}

export default MMHDlogolanding
