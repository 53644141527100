import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { handleAllGCD } from '../../../functions/GetClinicalData/GetClinicalDataFuncs';
//@ts-ignore
const Modal1 = ({ show, handleClose, patienthealthPlanName, handleShow2, patient, setModal2Text, setLoader, setBtnDisable }) => {
  const storedTheme = sessionStorage.getItem("theme");

  return (
    <div>
      <Modal show={show}
        onHide={handleClose}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={storedTheme == 'dark' ? "modal3_dark" : "modal3_light"}

      >
        <Modal.Body className='last1_modal'>
          By signing this request you are authorizing and providing
          consent for {patienthealthPlanName} Health Plan to reach out to
          your prior health plan and request your clinical data. If
          available we will retrive your clinical information and the
          compatible data will be made available via the patient access
          via APIs.
          <br />
          <Button
            variant='primary'
            onClick={() => handleAllGCD(patient, handleShow2, handleClose, setModal2Text, setLoader, setBtnDisable)}
            className={storedTheme != 'dark' ? "last1-btn btn_blue" : "last1-btn dark_btn_3"}
            data-testid='submit_GCD'
          >
            Submit my data
          </Button>
          <br />
          <Button variant='secondary'
            onClick={handleClose}
            className='last1-btn'>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Modal1
