import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import "./ConfirmDetails.css";
import { useHistory, withRouter } from "react-router";
import { patientActions } from "../../redux/feature/patientSlice";
import { useAppSelector, useAppDispatch } from "../../redux/app/hooks";

import { appInsights } from "../../components/AppInsights/appInsights";

import Logo from "../../components/Logo/Logo";
import { confirmPatientDetails } from "../../apis/apis";

import _ from "lodash";
import Bar1 from "../../components/SideBar/HorizontalStepper/Bar1";
import MobFooter from "../../components/Footer/MobFooter/MobFooter";
import { AxiosError } from "axios";
import SideBar from "../../components/SideBar/OtherSideBar/SideBar";
import { handleLogout } from "../../functions/LogOut/Logout";
import { socketUrl } from "../../websocket/websocket";
import useWebSocket from "react-use-websocket";
import InputField from "./InputField";
import { Initial_ConfirmDetails_change } from "../../constants/PageChanges";


function Confirm_Details() {
  const client_local = sessionStorage.getItem("client")
  const storedTheme = sessionStorage.getItem("theme");

  const [addressline, setAddressline] = useState("");
  const [pCity, setPCity] = useState("");
  const [pDistrict, setPDistrict] = useState("");
  const [pState, setPState] = useState("");
  const [pPostCode, setPPostCode] = useState("");
  const [pCountry, setPCountry] = useState("");

  const patient = useAppSelector((state) => state.patient);
  const dispatch = useAppDispatch();
  let history = useHistory();

  let tries = 0;
  const fetchData = async () => {
    appInsights.trackEvent({ name: `Member successfully logs in to Payer${client_local} ’s IDP.` })
    appInsights.trackEvent({ name: "Fill details started" });

    const FILL_DETAILS_URL = `${process.env.REACT_APP_API}/filldetails/`;
    try {

      const res = await confirmPatientDetails(client_local);
      appInsights.trackEvent({
        name: `AutoFill data URL: ${FILL_DETAILS_URL}`,
      });
      const data = await res.data;

      console.log(res.status);


      console.log(data);
      let [firstName, LastName, fullName, date, finalDate, gender, line, city, district, state, postalCode, country, address1, address2, phone, email] = Array(16).fill("");
      if (res.status === 204) {
        console.log("No patient Data Found")
        handleResponse(res.status);
        appInsights.trackEvent({ name: "No patient Data found while fetching data" })
        throw 'no patient data'
      }
      else if (data["entry"][0]["resource"]["resourceType"] === "Patient") {
        if (_.has(data, "entry[0].resource")) {
          if (_.has(data, "entry[0].resource.name")) {
            firstName = _.has(data, "entry[0].resource.name[0].given")
              ? data["entry"][0]["resource"]["name"][0]["given"]
              : "";

            LastName = _.has(data, "entry[0].resource.name[0].family")
              ? data["entry"][0]["resource"]["name"][0]["family"]
              : "";

            fullName = firstName + " " + LastName;
          }
          date = _.has(data, "entry[0].resource.birthDate")
            ? data["entry"][0]["resource"]["birthDate"]
            : "";
          if (date !== "") {
            const day = date.substr(8, 2);
            const month = date.substr(5, 2);
            const year = date.substr(0, 4);
            finalDate = day + "/" + month + "/" + year;
          }
          gender = _.has(data, "entry[0].resource.gender")
            ? data["entry"][0]["resource"]["gender"]
            : "";
          if (_.has(data, "entry[0].resource.address")) {
            line = _.has(data, "entry[0].resource.address[0].line")
              ? data["entry"][0]["resource"]["address"][0]["line"][0]
              : "";
            setAddressline(line)

            city = _.has(data, "entry[0].resource.address[0].city")
              ? data["entry"][0]["resource"]["address"][0]["city"]
              : "";
            setPCity(city)

            district = _.has(data, "entry[0].resource.address[0].district")
              ? data["entry"][0]["resource"]["address"][0]["district"]
              : "";
            setPDistrict(district)

            state = _.has(data, "entry[0].resource.address[0].state")
              ? data["entry"][0]["resource"]["address"][0]["state"]
              : "";
            setPState(state)

            postalCode = _.has(data, "entry[0].resource.address[0].postalCode")
              ? data["entry"][0]["resource"]["address"][0]["postalCode"]
              : "";
            setPPostCode(postalCode);

            country = _.has(data, "entry[0].resource.address[0].country")
              ? data["entry"][0]["resource"]["address"][0]["country"]
              : "";
            setPCountry(country)
            address1 = line + "," + city + "," + district;
            address2 = state + "," + postalCode + "," + country;
          }
          if (_.has(data, "entry[0].resource.telecom")) {
            for (let telecom of data["entry"][0]["resource"]["telecom"]) {
              phone = telecom["system"] === "phone" ? telecom["value"] : "";
              email = telecom["system"] === "email" ? telecom["value"] : "";
            }
          }
        }
        if (_.has(data, "entry[0].resource.id")) {
          try {
            const fhirId = _.has(data, "entry[0].resource.id")
              ? data["entry"][0]["resource"]["id"]
              : "";

            localStorage.setItem("newpayerfhirId", fhirId);
          } catch (error) {
            console.log(error);
            console.log("newpayerfhirId not present");
          }
        }
        dispatch(patientActions.updateFullName(fullName));
        dispatch(patientActions.updateDob(finalDate));
        dispatch(patientActions.updateGender(gender));
        dispatch(patientActions.updateAddress1(address1));
        dispatch(patientActions.updateAddress2(address2));
        dispatch(patientActions.updateEmail(email));
        dispatch(patientActions.updatePhoneNumber(phone));
        handleResponse(res.status);
        handleClickSendMessage()
      }
      else {
        console.log("No patient Data Found")
        handleResponse(res.status);
        appInsights.trackEvent({ name: "No patient Data found while fetching data" })
      }
      console.log(tries);
    } catch (err) {
      tries += 1;

      if (tries > 3) {

        console.log(tries);
        appInsights.trackException({
          exception: Error(
            `Failed to fetch patient data URL: ${FILL_DETAILS_URL}`
          ),
        });
        console.log("fetch failed");
        const error = err as AxiosError;
        console.log(err);
        if (err === 'no patient data') {
          handleResponse(204)
        }
        else {
          handleResponse(500)
        }
        return
      }
      else {

        fetchData();
      }
    }
  };
  
  const formSubmitandler = (e: any) => {
    e.preventDefault();
  };

  const [responseVar, setResponseVar] = useState(Initial_ConfirmDetails_change)
  const handleResponse = (res: number) => {
    if (res === 200) {
      appInsights.trackEvent({ name: "Member data retrieved successfully." })
      setResponseVar({
        ...responseVar,
        showForm : true,
        showSpinner : false,
        btnDisable : false,
        upperText : `${process.env.REACT_APP_UPPER_TEXT_CONFIRM_DETAILS}`,
        BtnText : "Next",
        errorMsg : "",
        contactService : `To update your information, call the member service number found on the back of your Member ID card.​`
      })
      setTaskStatus(1)
    }
    else if (res === 204) {
      appInsights.trackEvent({ name: "No member records found" })
      console.log("There is some error")
      setResponseVar({
        ...responseVar,
        showForm : false,
        showSpinner : false,
        btnDisable : false,
        BtnText : "Ok, Log me out",
        errorMsg : "We cannot find Data for you. We will now Log you out.",
      })
      setTaskStatus(-1)
    }
    else {
      appInsights.trackEvent({ name: "Failed to retrieve member data." })
      console.log("There is some error")
      setResponseVar({
        ...responseVar,
        showForm : false,
        showSpinner : false,
        btnDisable : false,
        BtnText : "Ok, Log me out",
        errorMsg : "We got into some issue.Please try again after sometime!!",
      })
      setTaskStatus(-1)
    }
  };
  const handleNext = () => {
    if (responseVar.BtnText === "Next") {
      history.push("/pick_plans")
    }
    else {
      handleLogout(history)
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  
  const [taskStatus, setTaskStatus] = useState(1)
  let customer_uuid=sessionStorage.getItem("customer_uuid")
  const { sendJsonMessage } = useWebSocket(socketUrl);


  //socketmsg
  const msg = {
    data: {
        initiation_time: new Date().toISOString(),
        end_time: new Date().toISOString(),
        customer_id: customer_uuid,
        status:taskStatus
    },
    msg_event: 'auth'
}
const msg2 = {
  data: {
      initiation_time: new Date().toISOString(),
      end_time: new Date().toISOString(),
      customer_id: customer_uuid,
      status:taskStatus
  },
  msg_event: 'confirm_details'
}
const handleClickSendMessage = useCallback(() => sendJsonMessage(msg), [msg]);

const handleClickSendMessage2 = useCallback(() => sendJsonMessage(msg2), [msg]);


  return (
    <Container fluid className={storedTheme !== 'dark' ? "page-wrapper" : "page-wrapper page-wrapper_dark"}>
      <Row className={storedTheme !== 'dark' ? "content-wrapper" : "content-wrapper page-wrapper_dark"}>
        <Col lg={4} className={storedTheme !== 'dark' ? "left-bar" : "left-bar left-bar_dark"}>
          <SideBar curr_path={0} msg_top="Welcome! first things first..." />
        </Col>
        <Col lg={8} xs={12} sm={12} className={storedTheme !== 'dark' ? "right-bar" : "right-bar right_bar_dark"}>
          <Logo />

          <div className="steps-wrapper-991">
            <Bar1 actstep={0} />
          </div>

          <Form
            className="form-complete"
            onSubmit={formSubmitandler}
            data-testid="form"
          >
            <p className="step-info-991">
              Confirm your Personal Information
              <br />
            </p>
            <Form.Text className="text-muted-confirm">
              {responseVar.upperText}
              <br />
            </Form.Text>
            <h3>{responseVar.errorMsg}</h3>

            {responseVar.showSpinner ? (
              <div className="spinnerdiv">

                <Spinner animation="border" variant="primary" />
              </div>
            ) : null}
            {responseVar.showForm ? (
              <>
                <InputField fieldValue={patient.fullName} fieldLabel={'Full Name'}/>
                <Row className="g-2">
                  <Col>
                    <InputField fieldValue={patient.dob} fieldLabel={'Date of Birth'}/>
                  </Col>
                  <Col>
                  <InputField fieldValue={patient.gender} fieldLabel={'Gender'}/>
                  </Col>
                </Row>
                <InputField fieldValue={addressline} fieldLabel={'Address line'}/>

                <Row className="g-2">
                <Col>
                <InputField fieldValue={pCity} fieldLabel={'City'}/>

                </Col>
                <Col>
                <InputField fieldValue={pDistrict} fieldLabel={'County'}/>

                </Col>
              </Row>
              <Row className="g-2">
                <Col>
                <InputField fieldValue={pState} fieldLabel={'State'}/>

                </Col>
                <Col>
                <InputField fieldValue={pPostCode} fieldLabel={'Postal Code'}/>

                </Col>
              </Row>
              <Row className="g-2">
                <Col>
                <InputField fieldValue={patient.email} fieldLabel={'Email'}/>

                </Col>
                <Col>
                <InputField fieldValue={patient.phoneNumber} fieldLabel={'Phone number'}/>

                </Col>
              </Row>
              </>
            ) : null}

            <div className="d-grid gap-2 buttons-confirm">
              <Button
                className={storedTheme !== 'dark' ? "btn_blue" : 'dark_btn_3'}
                size="lg"
                onClick={() => {
                  handleClickSendMessage2()
                  handleNext()
                }}
                disabled={responseVar.btnDisable}
              >
                {responseVar.BtnText}
              </Button>
            </div>
            <p className="input-width contactService">{responseVar.contactService}</p>
          </Form>
        </Col>
      </Row>
      <MobFooter />
    </Container>

  );
}

export default withRouter(Confirm_Details);
