import React, { useCallback, useEffect, useState } from 'react'
import { Button, Image, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { socketUrl } from '../../../websocket/websocket';
import SummaryBox from '../../SummaryBox/SummaryBox';
import { Typography } from '@mui/material';
//@ts-ignore
const ConsentModal1 = ({ show, handleClose, loadingImage, tick, processed, processedContent, handleShow2, responseStatus, responseStatusId, loading, ImageTick, handleShowNClose, errorImg, errorImage, uuidresponse, uuidgenerate, showuuid, waitingcontent, taskId }) => {

  let resrcs: any
  const resource = localStorage.getItem("resources")
  if (resource == null) {
    resrcs = []
  }
  else {
    resrcs = JSON.parse(resource)
  }
  const storedTheme = sessionStorage.getItem("theme");

  const [taskMsg, setTaskMsg] = useState('')
  const [taskStatus, setTaskStatus] = useState(0)

  console.log("response", responseStatus);
  useEffect(() => {
    console.log(responseStatus);

    if (responseStatus == true && taskMsg !== '') {
      setTaskStatus(1)
      setTaskMsg('success')
    }
    else {
      setTaskStatus(-1)
      setTaskMsg('fail')
    }
  }, [responseStatus, taskMsg])

  useEffect(() => {
    console.log(taskMsg, responseStatusId, taskStatus, "taskmsg");

  }, [responseStatus, taskMsg, taskStatus])

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className={storedTheme == 'dark' ? "modal3_dark" : "modal3_light"}
      >
        <Modal.Body className="consent-modal1">
          {loadingImage ? <div className="loader1"></div> : null}
          <div>
            {ImageTick ? (
              <Image src={tick} alt="done" className={storedTheme != 'dark' ? "blue-tick" : "blue-tick blue_tick_Dark"} />
            ) : null}
            {errorImg ? (
              <Image src={errorImage} alt='err' className="blue-tick" />
            ) : null}
            <span className="processing-title">{processed}</span>
          </div>
          <br />
          {processedContent}
          <br />
          {ImageTick ? (
            <Typography>
              Ref Id : {taskId}
            </Typography>
          ) : null}

          {waitingcontent}

          {uuidgenerate}
          <br />
          <b><i>
            {uuidresponse}
          </i></b>

          <br />
          {ImageTick ?
            <><SummaryBox resrcs={resrcs} />
              {/* <p>{process.env.REACT_APP_CLARITY_URL_TEXT}<Link to={'/view_data'}>View Data</Link> </p> */}
              {
                resrcs.TotalRecords.TotalCount > 15000 ? <p>{process.env.REACT_APP_CLARITY_URL_TEXT}</p> : null
              }
            </>
            : null}
          <Button
            className={storedTheme != 'dark' ? "consent-modal-btn btn_blue" : "consent-modal-btn dark_btn_3"}
            onClick={handleShowNClose}
            disabled={!responseStatus && !responseStatusId}
            data-testid='submitConsent'
          >
            {loading}
          </Button>

        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ConsentModal1
