import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import './SideBar.css'

const steps = [
    {
        label: "Confirm your personal Information",
    },
    {
        label: "Choose Your Old Health Plan",
    },
    {
        label: "Move Your Health Data",
    },
];
//@ts-ignore
const VerticalLinearStepper = ({ curr_path }) => {
    return (
        <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={curr_path} orientation="vertical" data-testid='verical_stepper'>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                        >
                            {step.label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
export default VerticalLinearStepper