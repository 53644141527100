import React, {useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, } from "react-bootstrap";
import "./GetClinicalData.css";
import { useHistory, withRouter } from "react-router";
import processingimg from "../../images/Processing.png";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import Logo from "../../components/Logo/Logo";
import Modal1 from "../../components/Modals/GetClinicalModals/Modal1";
import Modal2 from "../../components/Modals/GetClinicalModals/Modal2";
import Bar1 from "../../components/SideBar/HorizontalStepper/Bar1";
import MobFooter from "../../components/Footer/MobFooter/MobFooter";
import { handleHmny, handleupdatePayerCustomerSupportDetails, handleupdatePayerRepresentativeDetails, handleupdatePayerWebsiteDetails } from "../../functions/GetClinicalData/GetClinicalDataFuncs";

import SideBar from "../../components/SideBar/OtherSideBar/SideBar";
import { handleLogout } from "../../functions/LogOut/Logout";

function GetClinicalData() {
  const client_local = sessionStorage.getItem("client")

  const patient = useAppSelector((state) => state.patient);
  const dispatch = useAppDispatch();
  const [modal2Text, setModal2Text] = useState("");
  const [loader, setLoader] = useState(true);
  const [btnDisable, setBtnDisable] = useState(true)
  const [show, setShow] = useState(false);
  const [hmnyClient, setHmnyClient] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  let history = useHistory();
  const prev = "<-";
  const formSubmitHandler = (e: any) => {
    e.preventDefault();
  };

  const handlePrevHistory = () => {
    history.push("/plan_coverage");
  };

  const [remainingSeconds, setRemainingSeconds] = useState(15)

  const [linkTo, setLinkTo] = useState("");

  useEffect(() => {
    handleHmny(client_local, setLinkTo, setHmnyClient)
  }, [client_local]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let intervalId: any = 0
    if (!btnDisable) {
      intervalId = setInterval(() => {
        setRemainingSeconds((prevState: number) => (prevState -= 1))
        if (remainingSeconds === 1) {
          handleLogout(history)
        }
      }, 1000)
    } else if (btnDisable && remainingSeconds !== 0 && intervalId !== null) {
      clearInterval(intervalId)
    }
    return () => clearInterval(intervalId)

  }, [remainingSeconds, btnDisable])

  const storedTheme = sessionStorage.getItem("theme");

  return (
    <Container fluid className={storedTheme !== 'dark' ? "page-wrapper" : "page-wrapper page-wrapper_dark"}>
      <Row className={storedTheme !== 'dark' ? "content-wrapper" : "content-wrapper page-wrapper_dark"}>
        <Col lg={4} className={storedTheme !== 'dark' ? "left-bar" : "left-bar left-bar_dark"}>
          <SideBar curr_path={2} msg_top="One more step!..." />

        </Col>
        <Col lg={8} xs={12} sm={12} className={storedTheme !== 'dark' ? "right-bar" : "right-bar right_bar_dark"}>
          <Logo />
          <div className="steps-wrapper-991">
            <Bar1 actstep={2} />
          </div>

          <Form
            className="form-complete-clinical"
            onSubmit={formSubmitHandler}
            data-testid="form"
          >
            <p className="step-info-991">
              Get your Clinical Data
              <br />
            </p>
            <Form.Text className="text-muted">
              Confirm request and get your Clinical Data
              <br />
              <br />
            </Form.Text>
            <Form.Group
              className="mb-3"
              controlId="formBasicInsurer"
              aria-required="true"
            >
              <Form.Label>
                Does your payer have a website/url? If so please write below..
              </Form.Label>
              <Form.Control
                type="text"
                className={storedTheme !== 'dark' ? "input-width" : "input-width input_full_dark"}
                onChange={(e) => handleupdatePayerWebsiteDetails(e, dispatch)}
                value={patient.payerWebsiteDetails}
                data-testid="payerWebsiteDetails"
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="formBasicHealthPlan"
              aria-required="true"
            >
              <Form.Label>
                Does your payer have a customer support number? If so please
                write below..
              </Form.Label>
              <Form.Control
                type="text"
                className={storedTheme !== 'dark' ? "input-width" : "input-width input_full_dark"}
                onChange={(e) => handleupdatePayerCustomerSupportDetails(e, dispatch)}
                value={patient.payerCustomerSupportDetails}
                data-testid="payerCustomerSupportDetails"
              />
            </Form.Group>
            {hmnyClient &&
              <Form.Group
                className="mb-3"
                controlId="formBasicInsurer"
                aria-required="true"
              >
                <Form.Label>
                  Does your payer's representative has a contact number? If so please write below..
                </Form.Label>
                <Form.Control
                  type="text"
                  className="input-width-getanswers"
                  onChange={(e) => handleupdatePayerRepresentativeDetails(e, dispatch)}
                  value={patient.payerRepresentativeDetails}
                  data-testid="payerRepresentativeDetails"
                />
              </Form.Group>
            }
            <Row className="btns-down">
              <p onClick={handlePrevHistory}>{prev}Previous</p>
              <Col>
                <Button
                  type="submit"
                  className={storedTheme !== 'dark' ? "get-health-btn btn_blue" : "get-health-btn dark_btn_3"}
                  onClick={handleShow}
                >
                  Submit my payer details
                </Button>
              </Col>
            </Row>

            <Modal1
              show={show}
              handleClose={handleClose}
              patienthealthPlanName={patient.healthPlanName}
              handleShow2={handleShow2}
              patient={patient}
              setModal2Text={setModal2Text}
              setLoader={setLoader}
              setBtnDisable={setBtnDisable}
            />
            <Modal2
              show2={show2}
              handleClose2={handleClose2}
              processingimg={processingimg}
              handleLogOut={() => handleLogout(history)}
              modal2Text={modal2Text}
              loader={loader}
              btnDisable={btnDisable}
              remainingSeconds={remainingSeconds}
              linkTo={linkTo}
            />
          </Form>
        </Col>
      </Row>
      <MobFooter />
    </Container>
  );
}

export default withRouter(GetClinicalData);
