import axios from "axios";
import { appInsights } from "../components/AppInsights/appInsights";
import { getAccessToken } from "../functions/ConsentPage/ConsentFuncs";

export const fetchTermsAndConditions = async () => {

  try {
    return await axios.get(
      `${process.env.REACT_APP_API}/terms-conditions/`, {
      headers: {
        "Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
      },
    }
    );
  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const fetchPrivacyPolicy = async () => {

  try {
    return await axios.get(
      `${process.env.REACT_APP_API}/privacy-policy/`, {
      headers: {
        "Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
      },
    }
    );
  } catch (err) {
    throw new Error(`${err}`);
  }
};


export const confirmPatientDetails = async (client_local: any) => {
  let token = "";
  const accessToken = sessionStorage.getItem("auth");
  if (accessToken) {
    token = accessToken;
  }
  try {
    return await axios.get(`${process.env.REACT_APP_API}/api/v2/filldetails/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        AuthToken: token,
        "Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
        ClientName: client_local
      },
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const fetchHealthPlanList = async () => {
  const GET_PLANLIST_URL = `${process.env.REACT_APP_API}/api/v1/plans/with_payers`;
  try {
    const res = await axios.get(GET_PLANLIST_URL, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
      },
    })
    appInsights.trackEvent({ 'name': 'All the plans fetched into Landing Page' })
    return res;
  } catch (err) {
    //@ts-ignore
    appInsights.trackException({ exception: err })
    throw new Error(`${err}`);
  }
};

export const fetchActivePayerList = async () => {
  const GET_PAYERLIST_URL = `${process.env.REACT_APP_API}/payers/`;
  try {
    return await axios.get(GET_PAYERLIST_URL, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
      },
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const Postpayername = async (selectElement: any, payerName: any) => {
  const Payer = { oldplan: selectElement, oldplanName: payerName };
  const OLD_PAYER_GETDETAILS_FROM_DB_URL = `${process.env.REACT_APP_OAUTH_OLD_PAYER}/payer`;
  try {
    return await axios
      .post(OLD_PAYER_GETDETAILS_FROM_DB_URL, Payer)
      .then(() => console.log("Payer Sent"))

  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const getNewpayerlist = async (payerName: any, redirect: string | undefined) => {
  const Payer = { newplanName: payerName, redirect: redirect };
  const NEW_PAYER_GETDETAILS_FROM_DB_URL = `${process.env.REACT_APP_OAUTH_NEW_PAYER}/gotopayer`;
  try {
    return await axios
      .post(NEW_PAYER_GETDETAILS_FROM_DB_URL, Payer)
      .then(() => console.log("Payer Sent"))

  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const GetClientName = async () => {
  const OLD_PAYER_GETCLIENTDETAILS_URL = `${process.env.REACT_APP_OAUTH_NEW_PAYER}/clientname`;
  const requestOptions: object = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(OLD_PAYER_GETCLIENTDETAILS_URL, requestOptions);
    const data = await res.data;
    var clientname = data["ClientName"];
    console.log(data);
    console.log(clientname);

    return res;
  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const DownloadData = async (accessToken: string, clientname: string, is_safhir: number, newpayerslug: string, newpayername: string,last_updated_date: any,oneIdRecordStatus: any) => {
  
  const newpayerfhirId = localStorage.getItem("newpayerfhirId")
  const selectedResources = sessionStorage.getItem("Rselected")
  const importData = sessionStorage.getItem('importData')

  const requestOptions: object = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      AuthToken: accessToken,
      ClientName: clientname,
      newpayerfhirid: newpayerfhirId,
      issafhir: is_safhir,
      newpayerslug: newpayerslug,
      newpayername: newpayername,
      lastUpdated:  importData === 'new' ? last_updated_date : '',
      OneIdRecordStatus: importData === 'new' ? oneIdRecordStatus : false,
      "Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
      "ResourceList":selectedResources ? selectedResources : '[]'
    },
  };
  const DOWNLOAD_DATA_URL = `${process.env.REACT_APP_API}/download-data/`;
  try {
    return await axios.get(DOWNLOAD_DATA_URL, requestOptions);

  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const PostData = async (patientdata: any) => {

  const POST_DATA_URL = `${process.env.REACT_APP_API}/register/postdata/`;
  console.log(patientdata);

  try {
    return await axios.post(POST_DATA_URL, patientdata, {
      headers: {
        "Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
      }
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};

export const GetResourceList = async () => {
  const old_payer = sessionStorage.getItem("old_payer_name");
  const old_auth = sessionStorage.getItem("auth")
  const POST_DATA_URL = `${process.env.REACT_APP_API}/resourcesfromscopes/`;

  try {
    return await axios.get(POST_DATA_URL, {
      headers: {
        "Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
        //@ts-ignore
        ClientName: old_payer,
        //@ts-ignore
        AuthToken: old_auth,
      }
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};

//@ts-ignore
export const postDataToOneId = async (fhirId,name,pno,newpayer,oldpayer,pemail) => {
  const POST_DATA_URL = `${process.env.REACT_APP_ONEID_URL}/api/send_to_event_hub`;
  let a_token=sessionStorage.getItem("auth");
  let sendData=
    {
      "fhirId":fhirId,
      "name":name,
      "phone-number":pno,
      "latest-payer":newpayer,
      "previous-payer":oldpayer,
      "email":pemail,
      "appname":"MMHD",
      "auth-token":a_token,
      
  }
  try {
    return await axios.post(POST_DATA_URL, sendData);
  } catch (err) {
    // throw new Error(`${err}`);
    console.log(err);
    
  }
};

export const GetSinceDateValues  = async (newpayername: string,oldpayername: string) => {
  const newpayerfhirId = localStorage.getItem("newpayerfhirId")

  // const POST_DATA_URL = `${process.env.REACT_APP_API}/api/v1/listresourcebundle/`;
  const POST_DATA_URL = `${process.env.REACT_APP_API}/api/v1/oneid/patient/`;

  try {
    return await axios.get(POST_DATA_URL, {
      headers: {
        "Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
        //@ts-ignore
        "newpayerfhirid": newpayerfhirId,
        "newpayername" : newpayername,
        "oldpayername" : oldpayername,
      }
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};