import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import { Grid } from '@mui/material'
//@ts-ignore
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import { Card, Row, Col, Image } from 'react-bootstrap';
import { useState } from 'react';
import './TreeView.scss'
import { handleClick, handlePostPayerPickPlans } from '../../functions/PickPlans/PickPlansFuncs';
import { useAppDispatch } from '../../redux/app/hooks';
import tickMark from "../../images/tickMark.svg";

function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
    <TreeItem {...props} />
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));
//@ts-ignore
function CustomizedTreeViewPP({ payer_name, plans, selectElement, oid, setSelectElement, setpayername }) {
    const dispatch = useAppDispatch();

    const [spinner, setSpinner] = useState(true);
    const [showToast, setShowToast] = useState(false)
    const [show, setShow] = useState(false);

    let openbracket = "("
    let closebracket = ")"

    const handleCardClick = () => {
        handlePostPayerPickPlans(payer_name, setSpinner, setShowToast, setShow);
        sessionStorage.setItem("old_payer_name", payer_name)
    }
    let count = 0;
    let expandArray: any[] = []
    const storedTheme = sessionStorage.getItem("theme");

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Card className={storedTheme != 'dark' ? 'tree_view_card' : 'tree_view_card tree_view_card_dark'}>

                <TreeView
                    aria-label="customized"
                    // defaultExpanded={expandArray}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    // defaultEndIcon={<CloseSquare />}
                    sx={{ maxHeight: 264, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                >

                    <>

                        <StyledTreeItem nodeId={(count += 1).toString()} label={payer_name + "(" + plans.length + ")"} className="tree_header">

                            {plans.map((plan: any, i: any) => (
                                <>
                                    <Card className={storedTheme != 'dark' ? 'grouped_planslist' : 'grouped_planslist grouped_planslist_dark'} onClick={() => { handleCardClick(); handleClick(plan.contract_number, setSelectElement, setpayername, dispatch, payer_name, plan.legal_entity_name) }} key={i}>
                                        <Row>
                                            <Col>
                                                <Image src={`${plan.logo}`} alt='logo' className='listlogo' loading='lazy'/>
                                            </Col>
                                            <Col xs={7}>

                                                {plan.legal_entity_name}{openbracket} {plan.contract_number}{closebracket}
                                            </Col>
                                        </Row>
                                        <div>
                                            {selectElement === plan.contract_number ? (
                                                <img src={tickMark} alt="tickmark" className="tickMark" />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </Card>
                                </>
                            ))}
                        </StyledTreeItem>
                    </>
                </TreeView>
            </Card>

        </Grid>

    );
}
export default CustomizedTreeViewPP