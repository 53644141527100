import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './HomePage.scss'
import PersonIcon from '@mui/icons-material/Person';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PhoneIcon from '@mui/icons-material/Phone';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import hero_img from './img/hero-img.png'
import payorLogo from './img/payor.png'
import mmhdLogo from './img/mmhd-logo.png'
import mmhdLogoDark from './img/mmhd-logo-dark.png'
// import SwipeableTextMobileStepperHome from '../../components/Modals/ThreeStepModal/MobileStepperHome';
// import LogosCarosel from './LogosCarosel';
import ContactForm from './ContactForm';
import { fetchActivePayerList } from '../../apis/apis';
import HomeHeader from '../../components/HomeHeader/HomeHeader';
import HomeFooter from '../../components/Footer/HomeFooter/HomeFooter';
import { ScrollOnClick } from '../../components/ScrollOnClick/ScrollOnClick';


const SwipeableTextMobileStepper = lazy(() => import("../../components/Modals/ThreeStepModal/MobileStepperModal"));
const LogosCarosel = lazy(()=>import("./LogosCarosel"))
const IntroVideo = lazy(() => import("./IntoVideo"));

const HomePayor = () => {
    // const [logo, setLogo] = useState(mmhdLogo)
    var logo = mmhdLogo;

    const setDark = () => {
        logo = mmhdLogoDark;
        sessionStorage.setItem("theme", "dark");
        document.documentElement.setAttribute("data-theme", "dark");
    };

    const setLight = () => {
        logo = mmhdLogo;
        sessionStorage.setItem("theme", "light");
        document.documentElement.setAttribute("data-theme", "light");
    };

    const storedTheme = sessionStorage.getItem("theme");

    const prefersDark =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: light)").matches;

    const defaultDark =
        storedTheme === "dark" || (storedTheme === null && prefersDark);

    if (defaultDark) {
        setDark();
    }
  
    return (
        <div>
            <HomeHeader />
            <section id="hero" className="hero d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <h2 >Welcome To</h2>
                            <img src={logo} alt="" className='mmhd_home_logo'/>
                            <h2>Move Your Health Data From Your Earlier Health Plan In 3 Easy Steps</h2>
                            <h2>First Platform to Connect 300+ Health Plans</h2>
                            <div>
                                <div className="text-center text-lg-start">
                                    <Link  to={'/dashboard'}>
                                        <a className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                            <span className='dashboard_btn'>Go To MoveMyHealthData Dashboard</span>
                                            <ArrowRightAltIcon className='icons' />
                                        </a>
                                    </Link>
                                    <br /><br />
                                    <Link to={'/'}>
                                        <a><span>Are you a Member?</span></a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 hero-img">
                            <img src={hero_img} className="img-fluid" alt="" loading='lazy'/>
                        </div>

                    </div>
                    <ScrollOnClick/>
                    <div className="scroll-arrow-container">
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                    </div>

                </div>
            </section>
            <Suspense fallback={<div>Loading..</div>}>
            <LogosCarosel />
            </Suspense>
            <main id="main">
            <Suspense fallback={<div>Loading..</div>}>
                <section id="about" className="about">

                    <div className="container" data-aos="fade-up">
                        <div className="row gx-0">

                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                                <div className="content">
                                <Suspense fallback={<div>Loading..</div>}>
                                <SwipeableTextMobileStepper setCloseDisable={true} storedTheme={storedTheme} stepperHome={'home'} />
                                    </Suspense>
                                </div>
                            </div>

                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                            <Suspense fallback={<div>Loading..</div>}>
                                <IntroVideo/>
                                </Suspense>
                            </div>

                        </div>
                    </div>

                </section>
</Suspense>
<Suspense fallback={<div>Loading..</div>}>
                <section id="values" className="values">

                    <div className="container" data-aos="fade-up">

                        <header className="section-header">
                            <h2>Benefits Of MMHD</h2>

                        </header>

                        <div className="row">

                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                                <div className="box">
                                    <PersonIcon color='primary' />
                                    <h3>Patients</h3>
                                    <p className='sub-text'>Finally, healthcare data is available to the person who needs it most: You.</p>
                                    <p className="sub-pra">PATIENT DATA
                                        <li className="card-list">Bring all your medical data to one place.</li>
                                        <li className="card-list">Simple, secure, and easy access.</li>
                                        <li className="card-list">More knowledge equals better outcomes at a lower cost.</li>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
                                <div className="box">
                                    <img src={payorLogo} className="benefits-logo" alt='' />
                                    <h3>Payers</h3>
                                    <p className='sub-text'>With MoveMyHealthData, your customers are happier, and so are your margins.</p>
                                    <p className="sub-pra">PATIENT DATA
                                        <li className="card-list">Gain early insight into the health of new members .</li>
                                        <li className="card-list">Proactively align new members with the health maintenance programs they need.</li>
                                        <li className="card-list">Increased efficiency thanks to a better understanding of customer needs.</li>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
                                <div className="box">
                                    <LocalHospitalIcon color='primary' />
                                    <h3>Providers</h3>
                                    <p className='sub-text'>Operate more effectively with Health Plans.</p>
                                    <p className="sub-pra">PATIENT DATA
                                        <li className="card-list">Empower Patients to share their clinical information with their Health Plan</li>
                                        <li className="card-list">Enable Health Plans to use clinical insights to alert you to Care Gaps</li>
                                        <li className="card-list">Register with Onyx and enable your Patients to share their data from their Health Plan with you</li>
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>
</Suspense>
<Suspense fallback={<div>Loading..</div>}>
                <section id="contact" className="contact">

                    <div className="container" data-aos="fade-up">

                        <header className="section-header">
                            <h2>Contact</h2>
                        </header>

                        <div className="row gy-4">

                            <div className="col-lg-6">

                                <div className="contact-text">
                                    <h3><DateRangeIcon className='contact-icons' /> Schedule a demo</h3>
                                    <h3><PhoneIcon className='contact-icons' /> Connect with sales</h3>
                                    <h3><SupportAgentIcon className='contact-icons' /> Get support</h3>
                                </div>

                            </div>

                            <div className="col-lg-6">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </section>
</Suspense>
            </main>
            <Suspense fallback={<div>Loading..</div>}>
            <HomeFooter />
            </Suspense>
        </div>
    )
}

export default HomePayor
