import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import '../VerticalStepper/SideBar.css';
import DropDown from '../../DropDown/DropDown';

const steps = [
    '',
    '',
    '',
];
//@ts-ignore
const Bar1 = ({ actstep }) => {
    return (
        <><Box sx={{ width: '100%' }}>
            <Stepper activeStep={actstep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
            <div className="dropdown_991">
                <DropDown />
            </div>
        </>
    );
}
export default Bar1;