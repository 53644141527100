import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  InputGroup,
} from "react-bootstrap";
import "./LoginPage.css";

import { useHistory, withRouter } from "react-router-dom";
import ModalLogin1 from "../../components/Modals/LoginPageModals/ModalLogin1";
import LoginSideBar from "../../components/SideBar/LoginSideBar/LoginSideBar";
import DeskFooterLogin from "../../components/Footer/DeskFooter/DeskFooterLogin";
import MobFooter from "../../components/Footer/MobFooter/MobFooter";
import Logo from "../../components/Logo/Logo";
import { handlePostPayer } from "../../functions/LoginPage/LoginPageFuncs";
import ErrorToast from "../../components/Toasts/ErrorToast";
import GMDwithoutDD from "../../components/Logo/GMDwithoutDD";
import LoginPlanList from "../../components/PlansList/LoginPlanList";

//@ts-ignore
const LoginPage = ({ clientName, clientLogo, linkTo, oauth_link }) => {
  // window.location.reload()
  let history = useHistory()
  const sesplanlist = sessionStorage.getItem("plans_list")
  const storedTheme = sessionStorage.getItem("theme");

  const planlist = sesplanlist == null ? [] : JSON.parse(sesplanlist)
  const clientLocal = sessionStorage.getItem("client")
  const path = window.location.pathname
  const path2 = path.replace('/', '')
  console.log("path", path2);

  const [hmnyClient, sethmnyClient] = useState(false);

  const [spinner, setSpinner] = useState(true);

  const [btndisable, setBtndisable] = useState(true);

  const [showToast, setShowToast] = useState(false)

  const formSubmitHandler = (e: any) => {
    e.preventDefault();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const showSpinnerTrue = () => setSpinner(true)
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  var backArrow = "<--"

  return (
    <Container fluid className={storedTheme !== 'dark' ? "page-wrapper-login" : "page-wrapper-login page-wrapper-login_dark"}>
      <Row className={storedTheme !== 'dark' ? "content-wrapper-login" : "content-wrapper-login page-wrapper_dark"}>
        <Col lg={4} className={storedTheme !== 'dark' ? "left-bar-login" : "left-bar-login left-bar-login_dark"}>
          <Row>
            <Col className="other_gmd_logo">
              <GMDwithoutDD />
            </Col>
            <Col className="back_to_landing">
              <span onClick={() => history.goBack()} className='back_text'>{backArrow}Back</span>
            </Col>
          </Row>
          <LoginSideBar />
          <DeskFooterLogin />
        </Col>
        <Col lg={8} xs={12} sm={12} className={storedTheme !== 'dark' ? "right-bar-login" : "right-bar-login right_bar_dark"}>

          <>
            {/* <Image src={logologin} alt="clientLogo" className="clientLogo hmny_logo" /> */}
          </>
          <Logo />
          {!hmnyClient &&
            <Form
              className="form-complete-login"
              onSubmit={formSubmitHandler}
              data-testid="form"
            >
              <Row className="search_login_row">
                <Col>
                  <InputGroup className="mb-3 search_bar_login">

                    <Form.Control
                      placeholder="Search here By Health Plan Name, Payer name or HNo.."
                      aria-label="payer"
                      aria-describedby="basic-addon1"
                      onChange={e => {
                        // searchItems(e.target.value, setSearchInput, planlist, setFilteredResults,"");
                      }}
                      className={storedTheme !== 'dark' ? "search_input_box" : 'search_input_box search_input_box_dark'}
                      type='search'
                    />

                  </InputGroup>
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    type="submit"
                    className={storedTheme !== 'dark' ? "btn-login" : "btn-login btn-login_dark"}
                    onClick={() => {
                      handleShow();
                      handlePostPayer(clientName, setSpinner, setBtndisable, setShowToast, setShow);
                    }}
                    data-testid="btn_login"
                  >
                    Login with member ID and Password
                  </Button>
                </Col>
              </Row>


            </Form>
          }
          <span className="planlisted_txt">Plans Listed under {clientLocal}</span>
          <hr />
          <div className="login_plan_List">
            {searchInput.length > 0 ?
              <>
                {filteredResults.length > 0 ?
                  <LoginPlanList filteredItems={filteredResults} client_name={clientLocal} />
                  :
                  <p>No results found</p>
                }
              </>
              :
              <LoginPlanList filteredItems={planlist} client_name={clientLocal} />
            }
          </div>

          <ModalLogin1
            show={show}
            handleClose={handleClose}
            oauth_link={oauth_link}
            clientName={clientName}
            spinner={spinner}
            btndisable={btndisable}
            showSpinnerTrue={showSpinnerTrue} />

        </Col>
      </Row>
      <MobFooter />
      {showToast &&
        <ErrorToast show={showToast} setShowToast={setShowToast} />
      }
    </Container>
  );
};
//@ts-ignore
export default withRouter(LoginPage);
