import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { alpha, styled } from '@mui/material/styles'
import TreeView from '@mui/lab/TreeView'
import { Box, Grid } from '@mui/material'
//@ts-ignore
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem'
import Collapse from '@mui/material/Collapse'
// web.cjs is required for IE11 support
import { Card, Row, Col, Image } from 'react-bootstrap'
import { useCallback, useState } from 'react'
import { handlePostPayer } from '../../functions/LoginPage/LoginPageFuncs'
import ModalLogin3 from '../Modals/LoginPageModals/ModalLogin3'
import ErrorToast from '../Toasts/ErrorToast'
import './TreeView.scss'
import { socketUrl } from '../../websocket/websocket'
import useWebSocket from 'react-use-websocket'

function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    )
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    )
}
const StyledTreeItem = styled((props: TreeItemProps) => (
    <TreeItem {...props} />
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}))
//@ts-ignore
function CustomizedTreeView({ filteredItems }) {
    const storedTheme = sessionStorage.getItem("theme")
    let openbracket = "("
    let closebracket = ")"
    const [mainpayer, setMainpayer] = useState("")
    const [spinner, setSpinner] = useState(true)
    const [btndisable, setBtndisable] = useState(true)
    const [showToast, setShowToast] = useState(false)
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const showSpinnerTrue = () => setSpinner(true)
    let customer_uuid = sessionStorage.getItem("customer_uuid")
    const {sendJsonMessage } = useWebSocket(socketUrl)
    const msg = {
        data: {
            initiation_time: Date.now(),
            end_time: Date.now(),
            customer_id: customer_uuid
        },
        msg_event: 'selecting_current_payer'
    }
    const handleClickSendMessage = useCallback(() => sendJsonMessage(msg), [])
    //@ts-ignore
    const handleCardClick = (payername, card_img) => {
        handleClickSendMessage()
        setMainpayer(payername)
        handleShow()
        handlePostPayer(payername, setSpinner, setBtndisable, setShowToast, setShow)
        sessionStorage.setItem('logo', card_img)
    }
    let count = 0
    let count2 = 0
    let expandArray: any[] = []

    {
        filteredItems.map((val: any, k: any) => {
            expandArray.push((count2 += 1).toString())
        })
    }
    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={1} style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                {filteredItems.map((val: any, k: any) => (
                    <>
                    {val.name !=="CMS (BB)" ? 
                    <Grid item xs={12} sm={6} md={3} key={k}>
                        <Card className={storedTheme !== 'dark' ? 'tree_view_card' : 'tree_view_card tree_view_card_dark'}>

                            <TreeView
                                aria-label="customized"
                                defaultExpanded={expandArray}
                                defaultCollapseIcon={<MinusSquare />}
                                defaultExpandIcon={<PlusSquare />}
                                // defaultEndIcon={<CloseSquare />}
                                sx={{ maxHeight: 264, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                            >

                                <>

                                    <StyledTreeItem nodeId={(count += 1).toString()} label={val.name + "(" + val.plans.length + ")"} className="tree_header">

                                        {val.plans.map((plan: any, i: any) => (

                                            <Card key={i} className={storedTheme !== 'dark' ? 'grouped_planslist' : 'grouped_planslist grouped_planslist_dark'} onClick={() => handleCardClick(val.name, plan.logo)}>
                                                <Row>
                                                    <Col>
                                                        <Image src={`${plan.logo}`} alt='logo' className='listlogo' loading='lazy'/>
                                                    </Col>
                                                    <Col xs={8}>

                                                        {plan.legal_entity_name}{openbracket} {plan.contract_number}{closebracket}
                                                    </Col>
                                                </Row>
                                            </Card>

                                        ))}
                                    </StyledTreeItem>
                                </>
                            </TreeView>
                        </Card>

                    </Grid>
                    :null}
                    </>
                ))}
                
            </Grid>
            <ModalLogin3
                show={show}
                handleClose={handleClose}
                oauth_link={''}
                clientName={mainpayer}
                spinner={spinner}
                btndisable={btndisable}
                showSpinnerTrue={showSpinnerTrue} />
            {showToast &&
                <ErrorToast show={showToast} setShowToast={setShowToast} />
            }
        </Box>

    )
}
export default CustomizedTreeView