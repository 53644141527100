import { useEffect, useState } from "react";
import "./Logos.scss";

//@ts-ignore
const Logo = () => {
  const [CliLogo, setCliLogo] = useState('');
  const client_logo = sessionStorage.getItem("logo")
  //@ts-ignore
  var payerist: any;
  var obtlist;

  const get_payer_list = () => {
    obtlist = (localStorage.getItem('payers_list'))
    console.log(obtlist);
    if (obtlist === null) {
      obtlist = localStorage.getItem('payers_list');
      payerist = []
    }
    else {
      obtlist = localStorage.getItem('payers_list');
      //@ts-ignore
      payerist = JSON.parse(obtlist);
    }
  }
  get_payer_list()

  useEffect(() => {
    payerist.forEach((val: any) => {
      if (val.name == client_logo) {
        setCliLogo(val.clientlogo)
      }
    })
  }, []);


  return (
    <div>
      <img
        src={`${client_logo}`}
        alt="logo"
        className="onyxhealthLogo"
        data-testid='client_logo'
      />
    </div>
  );
};

export default Logo;
