import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
import './CarinBBTerms.scss'
import HomeFooter from "../../components/Footer/HomeFooter/HomeFooter";
//@ts-ignore
import carinpdf from './2020_CARIN_Code_of_Conduct_May2020.pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


export default function CarinBBTerms(props: { pdf: any; }) {
    const [numPages, setNumPages] = useState(null);
    //@ts-ignore
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const { pdf } = props;

    return (
        <><Document
            file={carinpdf}
            options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
            externalLinkTarget="_self"
        >
            {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
        </Document><HomeFooter /></>
    );
}