import * as React from 'react';
import './MenuBar.scss'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory } from 'react-router';
import MenuBar from './MenuBar';

//@ts-ignore
function MenuBarMobile({ obtlist, name }) {
    let history = useHistory();
    const storedTheme = sessionStorage.getItem("theme");

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleNavigate = (link: string) => {
        history.push(link)
    }
    return (
        <div>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className="menuBtn menubtn_mobile"
            >
                <MenuIcon className='menu_icon' /><span className='login_menu_txt'> {name}</span>
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem className={storedTheme !== 'dark' ? "menuBtn_list" : "menuBtn_list menuBtn_list_dark"} onClick={() => { handleNavigate("/") }}>Home</MenuItem>
                <a href="https://www.onyxhealth.io/mmhd/" target={'_blank'} rel="noreferrer">  <MenuItem className={storedTheme !== 'dark' ? "menuBtn_list" : "menuBtn_list menuBtn_list_dark"} >About</MenuItem></a>
                <MenuItem className={storedTheme !== 'dark' ? "menuBtn_list" : "menuBtn_list menuBtn_list_dark"} ><MenuBar obtlist={obtlist} name={'Payer Pages'} /></MenuItem>
                <a href="https://portal.safhir.io/" target={'_blank'} rel="noreferrer"> <MenuItem className={storedTheme !== 'dark' ? "menuBtn_list" : "menuBtn_list menuBtn_list_dark"}>Developer Portal</MenuItem></a>
                <MenuItem className={storedTheme !== 'dark' ? "menuBtn_list" : "menuBtn_list menuBtn_list_dark"} onClick={() => { handleNavigate("/#contact") }}>Contact Us</MenuItem>
            </Menu>
        </div>
    );
}
export default MenuBarMobile