import React, { useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { handlePostPayer } from '../../../functions/LoginPage/LoginPageFuncs'
import ErrorToast from '../../Toasts/ErrorToast'

//@ts-ignore
const RedirectModal = ({payername,showRedirect,handleCloseRedirect, showToast, setShowToast}) => {
  
useEffect(() => {
  if(showToast){
    handleCloseRedirect()
  }
}, [showToast])

  return (
    <div>
      <Modal 
      show={showRedirect} 
      onHide={handleCloseRedirect} 
      backdrop="static"
      keyboard={false} aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Body style={{'alignItems':'center','textAlign':'center','alignSelf':'center'}}>
          <Spinner animation="border" variant="primary" />
          <h2>Redirecting...</h2>
          You'll get redirected to <b>{payername}</b> Login Page</Modal.Body>
      </Modal>
      {showToast &&
                <ErrorToast show={showToast} setShowToast={setShowToast} />
            }
    </div>
  )
}

export default RedirectModal