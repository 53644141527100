import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Card, Col, Image, Row } from 'react-bootstrap';
import './PlanList.scss'
import { useCallback, useState } from 'react';
import { handlePostPayer } from '../../functions/LoginPage/LoginPageFuncs';
import ErrorToast from '../Toasts/ErrorToast';
import ModalLogin3 from '../Modals/LoginPageModals/ModalLogin3';
import useWebSocket from 'react-use-websocket';
import { socketUrl } from '../../websocket/websocket';

//@ts-ignore
function GridComponent({ payername, planname, contract_number, card_img }) {
    const storedTheme = sessionStorage.getItem("theme");

    const [spinner, setSpinner] = useState(true);
    const [btndisable, setBtndisable] = useState(true);
    const [showToast, setShowToast] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const showSpinnerTrue = () => setSpinner(true)

    let customer_uuid=sessionStorage.getItem("customer_uuid")
    const { sendJsonMessage } = useWebSocket(socketUrl);
    const msg = {
      data: {
          initiation_time: Date.now(),
          end_time: Date.now(),
          customer_id: customer_uuid
      },
      msg_event: 'selecting_current_payer'
  }
  const handleClickSendMessage = useCallback(() => sendJsonMessage(msg), []);
    const handleCardClick = () => {
        handleClickSendMessage()
        handleShow();
        handlePostPayer(payername, setSpinner, setBtndisable, setShowToast, setShow);
        sessionStorage.setItem('logo', card_img)
    }
    return (
        <Grid item xs={12} sm={6} md={3} className='card_item'>
            <Card className={storedTheme != 'dark' ? 'planlist2_card' : 'planlist2_card planlist2_card_dark'} onClick={handleCardClick}>
                <Row>
                    <Col>
                        <Image src={`${card_img}`} alt='client_logo' className='plan2_card_img' loading='lazy'/>
                    </Col>
                    <Col xs={7} className='planlist2_card_content' data-testid = 'card_payer_name'>
                        {payername}<br />
                        <b className='card_plan2_name'>{planname}</b><br />
                        {contract_number}

                    </Col>
                </Row>
            </Card>
            <ModalLogin3
                show={show}
                handleClose={handleClose}
                oauth_link={''}
                clientName={payername}
                spinner={spinner}
                btndisable={btndisable}
                showSpinnerTrue={showSpinnerTrue} />
            {showToast &&
                <ErrorToast show={showToast} setShowToast={setShowToast} />
            }
        </Grid>
    )
}
//@ts-ignore

function PlanList2({ filteredItems }) {
    console.log('result', filteredItems);

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={1} style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                {filteredItems.map((val: any, i: any) => (
                    <>
                    {val.name !=="CMS (BB)" ? 
                    <>
                    {val.plans.map((plan: any, i2: any) => (
                        <GridComponent payername={val.name} planname={plan.legal_entity_name} contract_number={plan.contract_number} card_img={plan.logo} key={i2} />
                    ))}
                    </>
                    :null}
                    </>
                ))}

            </Grid>
        </Box>
    );
}
export default PlanList2