import { configureStore } from '@reduxjs/toolkit'
import AuthSlice from '../feature/AuthSlice'
import patientReducer from '../feature/patientSlice'
import UIReducer from '../feature/UISlice'

export const store = configureStore({
    reducer: {
        patient: patientReducer,
        UI: UIReducer,
        auth: AuthSlice
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
