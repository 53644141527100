import React, { useCallback } from 'react'
import { Button, Modal } from 'react-bootstrap'
import useWebSocket from 'react-use-websocket';
import { socketUrl } from '../../../websocket/websocket';
//@ts-ignore
const ConsentModal2 = ({ show2, handleClose2, processingimg, handelLogOut, modalmsg, remainingSeconds }) => {
  const storedTheme = sessionStorage.getItem("theme");

  let customer_uuid=sessionStorage.getItem("customer_uuid")
  const { sendMessage, lastMessage, readyState,sendJsonMessage } = useWebSocket(socketUrl);
  const msg = {
    data: {
        initiation_time: new Date().toISOString(),
        end_time: new Date().toISOString(),
        customer_id: customer_uuid,
        status:1
    },
    msg_event: 'logout'
}
const handleClickSendMessage = useCallback(() => sendJsonMessage(msg), []);
  return (
    <div>
      <Modal
        show={show2}
        onHide={handleClose2}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={storedTheme == 'dark' ? "modal3_dark" : "modal3_light"}
      >
        <Modal.Body className="consent-modal2">
          <img src={processingimg} alt="processingimg" />
          <br />
          {modalmsg}
          <br />
          You will be automatically logged out in{" "}
          {remainingSeconds.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
          })}{" "}
          seconds. To Logout immediately, click the button below.
          <br />
          <Button
            className={storedTheme != 'dark' ? "consent-modal-btn2 btn_blue" : "consent-modal-btn2 dark_btn_3"}
            onClick={()=>{handelLogOut();handleClickSendMessage()}}>
            Ok, Log Me Out (
            {remainingSeconds.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })})
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ConsentModal2
