import { DownloadData, GetClientName, GetResourceList, GetSinceDateValues, PostData, postDataToOneId } from "../../apis/apis"
import { appInsights } from "../../components/AppInsights/appInsights"
import { getPayer } from "../SearchFuncs/SearchFuncs";

//@ts-ignore
export const handleClick = (setUuidresponse, uuid, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, handleShow, getPatientData, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3,setTaskId) => {

    handleShow()
    appInsights.trackEvent({ name: "Member accepts T&C in consent page" })
    var accessToken = getAccessToken();
    getPatientData();
    getClientName(accessToken, setUuidresponse, uuid, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, getPatientData, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3,setTaskId);
};

export const getAccessToken = () => {
    const token = sessionStorage.getItem("auth");
    return token ?? "";
};

//@ts-ignore
export const getClientName = async (accessToken: string, setUuidresponse, uuid, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, getPatientData, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3,setTaskId) => {
    try {
        const res = await GetClientName();
        const responsedata = res.data;
        var clientname = responsedata["ClientName"];
        downloadData(accessToken, clientname, setUuidresponse, uuid, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, getPatientData, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3,setTaskId);
    } catch (error) {
        console.log(error);
        downloadDataResponse(500, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3)
        setUuidresponse(uuid)
    }
};


//@ts-ignore
export const downloadData = async (accessToken: string, clientname: string, setUuidresponse, uuid, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, getPatientData, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3,setTaskId) => {
    var taskId = ""
    const DOWNLOAD_DATA_URL = `${process.env.REACT_APP_API}/download-data/`;

    const FHIR_URL = process.env.REACT_APP_FHIR_URL;
    let is_safhir = 0;
    let newpayerslug = ""
    let newpayername = ""
    let newpayer = sessionStorage.getItem("client")
    if (newpayer === null) {
        newpayername = ""
    }

    const payers = getPayer()
    payers.map((val: any) => {
        if (val.name === newpayer) {
            if (val.issafhir === true) {
                is_safhir = 1
            }
            else {
                is_safhir = 0
            }
            newpayerslug = val.slug
            newpayername = val.name
        }
    })

    try {
        const oneIdData =await GetSinceDateValues(newpayername,clientname)
        const sinceData = oneIdData.data;
        let last_updated_date = sinceData["last_Updated"] ?? ''
        let oneIdRecordStatus = sinceData["OneIdRecordStatus"] ?? ''

        console.log(last_updated_date,oneIdRecordStatus, "last updated");

        appInsights.trackEvent({ name: "Record Uploader queued." })
        appInsights.trackEvent({ name: "Record Uploader batch starts." })
        
        const res = await DownloadData(accessToken, clientname, is_safhir, newpayerslug, newpayername,last_updated_date,oneIdRecordStatus);
        const data = res.data;
        localStorage.setItem("resources", JSON.stringify(data))
        taskId = data["TaskId"];
        setTaskId(taskId);

        postData(taskId, setUuidresponse, uuid, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, getPatientData, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3);
        appInsights.trackEvent({
            name: `Download data URL: ${DOWNLOAD_DATA_URL}, fhir_url: ${FHIR_URL}`,
        });
        if (res.status !== 200) {
            downloadDataResponse(res.status, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3);
        }
    } catch (error) {
        console.log(error);
        //@ts-ignore
        appInsights.trackException({ exception: error })
        appInsights.trackEvent({ name: `Record insertion failed due to ${error}` })
        downloadDataResponse(500, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3);
        setUuidresponse(uuid)

    }
};

//@ts-ignore
export const postData = async (taskId: string, setUuidresponse, uuid, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, getPatientData, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3) => {
    const POST_DATA_URL = `${process.env.REACT_APP_API}/register/postdata/`;
    const newpayername = sessionStorage.getItem("client") ? sessionStorage.getItem("client") : ""

    appInsights.trackEvent({ name: "Validation of records inserted successfully. " })
    const patientData = getPatientData();
    const patientdata = {
        fullname: (patientData["fullName"] === '') ? null : patientData["fullName"],
        dob: (patientData["dob"] === '') ? null : patientData["dob"],
        gender: (patientData["gender"] === '') ? null : patientData["gender"],
        address1: (patientData["address1"] === '') ? null : patientData["address1"],
        address2: (patientData["address2"] === '') ? null : patientData["address2"],
        email: (patientData["email"] === '') ? null : patientData["email"],
        phonenumber: (patientData["phoneNumber"] === '') ? null : patientData["phoneNumber"],
        healthplanchoice: (patientData["healthPlanChoice"] === '') ? null : patientData["healthPlanChoice"],
        taskid: taskId == '' || null ? null : taskId,
        newpayername: newpayername == '' || null ? null : newpayername
    };
    console.log(patientData);
    try {
        const res = await PostData(patientdata);
        await res.data;
        appInsights.trackEvent({ name: `Post Data URL: ${POST_DATA_URL}` });
        let newpayerfhirId=localStorage.getItem("newpayerfhirId")
        const client_local = sessionStorage.getItem("client");
        let oldpayer = sessionStorage.getItem("old_payer_name")

        if(res.status === 201){
            if(Object.keys(patientData).length!==0){
                postDataToOneId(newpayerfhirId,patientData.fullName,patientData.phoneNumber,client_local,oldpayer,patientData.email)
              }
        }
        downloadDataResponse(res.status, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3)

    } catch (error) {
        console.log(error);
        appInsights.trackEvent({ name: `Posting the Data to DB failed due to ${error}` })
        //@ts-ignore
        appInsights.trackException({ exception: error })
        downloadDataResponse(500, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3)
        setUuidresponse(uuid)
    }
};
//@ts-ignore
export const downloadDataResponse = (res: number, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3) => {
    console.log(res);
    if (res == 200 || res == 201) {
        appInsights.trackEvent({ name: "Records successfully inserted." })
        SetResponseStatus(true)
        SetResponseStatusId(true);
        setProcessed("Processed");
        setProcessedContent("Data has been processed successfully!!!");
        setLoading("Ok");
        setLoadingImage(false);
        setImageTick(true);
        setWaitingcontent("")
        setModalmsg("Your data has been imported. Your healthplan records will be updated with the imported information, within a couple of hours. We will now log you out.")
        setUuidgenerate("Below is the Summary of your Data : ");
        setBtnTxt("Processed!!")
        handleClickSendMessage2()
    }
    else {
        appInsights.trackEvent({ name: "Record insertion failed." })
        SetResponseStatusId(true);
        setProcessed("Oops!!!");
        setProcessedContent("Sorry for the inconvenience");
        setLoading("Ok");
        setLoadingImage(false);
        setShowuuid(true);
        seterrorImg(true);
        setUuidgenerate("Please note this ID for further reference : ");
        setWaitingcontent("");
        setModalmsg("We will now Log you out.")
        setBtnTxt("Failed!!")
        handleClickSendMessage3()
    }
};

//@ts-ignore
export const handleShowNClose = (handleShow2, handleClose) => {
    handleShow2();
    handleClose();
};

//@ts-ignore
export const fetchAllResources = async(setAllResourceList,setAllResourceSpinner,setrModalmsg,tries) =>{
    try{
        const res = await GetResourceList();
        setAllResourceList(res.data)
        setAllResourceSpinner(false)
        setrModalmsg('')
    }
    catch(e){
        tries+=1;
        if(tries>=3){
            fetchAllResources(setAllResourceList,setAllResourceSpinner,setrModalmsg,tries)
        }
        else{
        console.log(e);
        setAllResourceSpinner(false)
        setrModalmsg("Error while loading data for you, please try again after sometime.")
        }
    }
}

//@ts-ignore
export const setBtnTxtFunc=(setBtnTxt,setDataSelectModal,setDisableBtn)=>{
    setBtnTxt("Processing...")
    setDataSelectModal(true)
    setDisableBtn(true);
}