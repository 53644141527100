import { History } from "history";
import { SetStateAction } from "react";
import { PostData } from "../../apis/apis";
import { appInsights } from "../../components/AppInsights/appInsights";
import { patientActions } from "../../redux/feature/patientSlice";
//@ts-ignore
export const handleClick = (patient: { insurer: string; healthPlanName: string; yourName: string; memberId: string; subscriberId: string; groupId: string; planId: string; payerWebsiteDetails: string; payerCustomerSupportDetails: string; payerRepresentativeDetails: string; dataCategories: string; optionalYourName: string; dateOfSignature: string; fullName: string; dob: string; gender: string; email: string; address1: string; address2: string; phoneNumber: string; }, setModal2Text, setLoader, setBtnDisable) => {

    const data = {
        insurer: (patient.insurer === '') ? null : patient.insurer,
        healthplanname: (patient.healthPlanName === '') ? null : patient.healthPlanName,
        yourname: (patient.yourName === '') ? null : patient.yourName,
        memberid: (patient.memberId === '') ? null : patient.memberId,
        subscriberid: (patient.subscriberId === '') ? null : patient.subscriberId,
        groupid: (patient.groupId === '') ? null : patient.groupId,
        planid: (patient.planId === '') ? null : patient.planId,
        payerwebsitedetails: (patient.payerWebsiteDetails === '') ? null : patient.payerWebsiteDetails,
        payercustomersupportdetails: (patient.payerCustomerSupportDetails === '') ? null : patient.payerCustomerSupportDetails,
        payerrepresentativedetails: (patient.payerRepresentativeDetails === '') ? null : patient.payerRepresentativeDetails,
        datacategories: (patient.dataCategories === '') ? null : patient.dataCategories,
        optionalyourname: (patient.optionalYourName === '') ? null : patient.optionalYourName,
        dateofsignature: (patient.dateOfSignature === '') ? null : patient.dateOfSignature,
        fullname: (patient.fullName === '') ? null : patient.fullName,
        dob: (patient.dob === '') ? null : patient.dob,
        gender: (patient.gender === '') ? null : patient.gender,
        email: (patient.email === '') ? null : patient.email,
        address1: (patient.address1 === '') ? null : patient.address1,
        address2: (patient.address2 === '') ? null : patient.address2,
        phonenumber: (patient.phoneNumber === '') ? null : patient.phoneNumber,
    };
    console.log(data);
    //@ts-ignore
    postData(data, setModal2Text, setLoader, setBtnDisable);
};

//@ts-ignore
export const postData = async (patientdata: object, setModal2Text, setLoader, setBtnDisable) => {
    const POST_DATA_URL = `${process.env.REACT_APP_API}/register/postdata/`;
    const newpayer = sessionStorage.getItem("client") ? sessionStorage.getItem("client") : ""
    const patData = {
        newpayername: newpayer
    }
    const patientData = {
        ...patientdata,
        ...patData
    }
    console.log(patientData);

    try {
        const res = await PostData(patientData);
        const data = await res.data;
        appInsights.trackEvent({
            name: `Clinical Data Post URL: ${POST_DATA_URL}`,
        });
        handleresponse(res.status, setModal2Text, setLoader, setBtnDisable)
        console.log(data);
    } catch (error) {
        console.log(error);
        handleresponse(500, setModal2Text, setLoader, setBtnDisable)
    }
};

//@ts-ignore
export const handleresponse = (res: number, setModal2Text, setLoader, setBtnDisable) => {
    if (res == 201) {
        appInsights.trackEvent({ name: "New payer data Posted successfully!!!" })
        setModal2Text("Check back in 1 week to see if we have been able to register with your prior payer.")
        setLoader(false)
        setBtnDisable(false)
    }
    else {
        appInsights.trackEvent({ name: "Posting New payer data Failed" })
        setModal2Text("Something went wrong while posting your data. Please try again after some time.")
        setLoader(false)
        setBtnDisable(false)
    }
}

//@ts-ignore
export const handleAllGCD = (patient, handleShow2, handleClose, setModal2Text, setLoader, setBtnDisable) => {
    appInsights.trackEvent({ name: "Member submits the not listed payer details." })
    handleClick(patient, setModal2Text, setLoader, setBtnDisable);
    handleShow2();
    handleClose();
}

//@ts-ignore
export const handleupdatePayerWebsiteDetails = (e, dispatch) => {
    dispatch(
        patientActions.updatePayerWebsiteDetails(e.target.value)
    )
}
//@ts-ignore
export const handleupdatePayerCustomerSupportDetails = (e, dispatch) =>
    dispatch(
        patientActions.updatePayerCustomerSupportDetails(
            e.target.value
        )
    )

//@ts-ignore
export const handleupdatePayerRepresentativeDetails = (e, dispatch) =>
    dispatch(
        patientActions.updatePayerRepresentativeDetails(
            e.target.value
        )
    )

export const handleHmny = (client_local: string | null, setLinkTo: { (value: SetStateAction<string>): void; (arg0: string): void; }, setHmnyClient: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; }) => {
    if (client_local === "HIGHMARK") {
        setHmnyClient(true)
    }
}
