import { History } from "history";
import { patientActions } from "../../redux/feature/patientSlice";

//@ts-ignore
export const handleupdateInsurer = (e, dispatch) =>
    dispatch(patientActions.updateInsurer(e.target.value));

//@ts-ignore
export const handleupdateHealthPlanName = (e, dispatch) =>
    dispatch(patientActions.updateHealthPlanName(e.target.value));

//@ts-ignore
export const handleChangeYourName = (e, dispatch) => {
    dispatch(patientActions.updateYourName(e.currentTarget.value));
};

//@ts-ignore
export const handleChangePlanId = (e, dispatch) => {
    dispatch(patientActions.updatePlanId(e.currentTarget.value));
};


//@ts-ignore
export const handleChangeMemberId = (e, dispatch) => {
    dispatch(patientActions.updateMemberId(e.currentTarget.value));
};

//@ts-ignore
export const handleChangeSubscriberId = (e, dispatch) => {
    dispatch(patientActions.updateSubscriberId(e.currentTarget.value));
};

//@ts-ignore
export const handleChangeGroupId = (e, dispatch) => {
    dispatch(patientActions.updateGroupId(e.currentTarget.value));
};
