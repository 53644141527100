import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react'
import { Button, CloseButton, Modal} from 'react-bootstrap'
import SwipeableTextMobileStepper from './MobileStepperModal'
//@ts-ignore
// import introvideo from "../../../pages/HomePage/mmhd_intro2.mp4"
import './ThreeStepModal.scss'
import '../../../pages/LandingPage/Landing_darktheme.scss'
import { socketUrl } from '../../../websocket/websocket'
import useWebSocket from 'react-use-websocket'
// import IntoVideo from '../../../pages/HomePage/IntoVideo'
const IntroVideo = lazy(() => import("../../../pages/HomePage/IntoVideo"));

//@ts-ignore
const ThreeStepModal = ({ show, handleClose, setShow }) => {
    const storedTheme = sessionStorage.getItem("theme")
    const modalshow = sessionStorage.getItem("3modal")

    const [watchVideo, setWatchVideo] = useState(false)
    const [watchTxt, setWatchTxt] = useState("Watch Video")
    const [closeDisable, setCloseDisable] = useState(true)

    const handleClickVideo = (txt: string) => {
        if (txt === 'Watch Video') {
            setWatchTxt('View as steps')
            setWatchVideo(true)
            setCloseDisable(false)
        }
        else {
            setWatchTxt('Watch Video')
            setWatchVideo(false)
        }
    }

    let customer_uuid = sessionStorage.getItem("customer_uuid")
    const { sendJsonMessage } = useWebSocket(socketUrl)
    const msg = {
        data: {
            initiation_time: Date.now(),
            end_time: Date.now(),
            customer_id: customer_uuid
        },
        msg_event: 'closing_modal'
    }
    const handleClickSendMessage = useCallback(() => sendJsonMessage(msg), [])

    useEffect(() => {
        sessionStorage.setItem("3modal", "1")
    }, [])

    return (
        <div>
            {modalshow === "1" ?
                <Modal
                    show={show}
                    onHide={handleClose}
                    animation={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    size='xl'
                    className={storedTheme !== 'dark' ? '' : 'three_step_modal_wrapper_dark'}

                >
                    <Modal.Header>
                        <Modal.Title>Quick Guide :</Modal.Title>
                        <span style={{ 'textAlign': 'right' }}>
                            <Button className={storedTheme !== 'dark' ? 'watch_tut_modal' : 'dark_btn_3'} onClick={() => handleClickVideo(watchTxt)}>
                                {watchTxt}
                            </Button>
                            <CloseButton onClick={() => { handleClickSendMessage(); handleClose() }} disabled={closeDisable} />
                        </span>
                    </Modal.Header>
                    <Modal.Body style={{ 'textAlign': 'center' }} >

                        {!watchVideo ?
                            <>
                                <SwipeableTextMobileStepper setCloseDisable={setCloseDisable} storedTheme={storedTheme} stepperHome={'normal'} /></>
                            :
                            <div className='intro_video'>
                                <Suspense fallback={<div>Loading..</div>}>
                                <IntroVideo/>
                                </Suspense>
                            </div>}
                        <a onClick={() => {
                            handleClickSendMessage()
                            handleClose()
                        }} style={{ 'color': '#0d6efd', 'cursor': 'pointer' }}>Skip Intro</a>


                    </Modal.Body>
                </Modal>
                : null}
        </div>
    )
}

export default ThreeStepModal