import React from 'react'
import Polygon from "../../../images/Polygon.svg";
import PolygonDark from "../../../images/Polygon_dark.svg";
import DropDown from '../../DropDown/DropDown';
import DeskFooter from '../../Footer/DeskFooter/DeskFooter'
import DeskFooterLogin from '../../Footer/DeskFooter/DeskFooterLogin';
import GMDlogo from '../../Logo/GMDlogo'
import VerticalLinearStepper from '../VerticalStepper/VerticalLinearStepper'

//@ts-ignore
const SideBar = ({ curr_path, msg_top }) => {
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <div>
            <img src={storedTheme != 'dark' ? Polygon : PolygonDark} alt="polygon" className="pointer-1" />
            <GMDlogo />

            <div className="steps-wrapper">
                <div className="msg-top" data-testid="welcome">
                    {msg_top}
                </div>
                <div className="stepper_before">
                    <VerticalLinearStepper curr_path={curr_path} />
                </div>
                <DeskFooterLogin />
            </div>
        </div>
    )
}

export default SideBar