import useWebSocket from 'react-use-websocket';

//@ts-ignore
export const handleWebSocket=(msg_event,chatSocket)=>{
    console.log('connected to websocket');
    let customer_uuid=sessionStorage.getItem("customer_uuid")
    console.log(customer_uuid,msg_event);
    
    const msg = {
        data: {
            initiation_time: Date.now(),
            end_time: Date.now(),
            customer_id: customer_uuid
        },
        msg_event: msg_event
    }

    chatSocket.send(JSON.stringify(msg))
}
let customer_uuid=sessionStorage.getItem("customer_uuid")

if(customer_uuid===null){
    const path=window.location.pathname.split("/")
    customer_uuid=path[path.length-2]
}
console.log(customer_uuid,"uuid");

export const socketUrl=`${process.env.REACT_APP_WEBSOCKET_URL}/${customer_uuid}/`;
