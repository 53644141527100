import Grid from '@mui/material/Grid';
import './PlanList.scss';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { handlePostPayerPickPlans } from '../../functions/PickPlans/PickPlansFuncs';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import tickMark from "../../images/tickMark.svg";
import { Card, Row, Col, Image } from 'react-bootstrap';

//@ts-ignore
function GridComponent({ card_img, planName, HNo, payer_name, handle_old_payer, selectElement, oid }) {

    const [spinner, setSpinner] = useState(true);
    const [showToast, setShowToast] = useState(false)
    const [show, setShow] = useState(false);
    
    const handleCardClick = () => {
        handle_old_payer()
        handlePostPayerPickPlans(payer_name, setSpinner, setShowToast, setShow);
        sessionStorage.setItem("old_payer_name", payer_name)
    }
    const storedTheme = sessionStorage.getItem("theme");

    return (
        <Grid item xs={12} sm={6} md={4} className='card_item'>
            <Card className={storedTheme !== 'dark' ? 'planlist2_card' : 'planlist2_card planlist2_card_dark'} onClick={handleCardClick}>
                <Row>
                    <Col>
                        <Image src={`${card_img}`} alt='client_logo' className='plan2_card_img' loading='lazy'/>
                    </Col>
                    <Col xs={6} className='planlist2_card_content'>
                        {payer_name}<br />
                        <b className='card_plan2_name'>{planName}</b><br />
                        {HNo}

                    </Col>
                </Row>
                <div>
                    {selectElement === oid ? (
                        <img src={tickMark} alt="tickmark" className="tickMark" />
                    ) : (
                        ""
                    )}
                </div>
            </Card>

        </Grid>
    )
}
export default GridComponent