import React from 'react';
import { Link } from 'react-router-dom';
import './MobFooter.css'
import onyxlogo from "../../../images/Onyx.svg";

const MobFooter = () => {
    const storedTheme = sessionStorage.getItem("theme");

    return <div >
        <footer className={storedTheme != 'dark' ? 'mob_footer' : 'mob_footer footer_wrapper_dark'}>
            <div className='mob_left'>
                <div className="onyxDown_mob">
                    <p className="onyx-text mob_view_text">Powered By : </p>
                    <img
                        src={onyxlogo}
                        alt="onyxLogo"
                        className="onyx-icon mob_view_icon"
                        data-testid="onyxLogo"
                    />
                </div>
            </div>
            <div className='mob_right'>
                <p className='tplinks_mob'>|<a href="https://www.onyxhealth.io/" target={"_blank"} rel="noreferrer" className='TPlink_mob'>About Us</a> | <Link to="/terms-of-service" className='TPlink_mob'>Terms Of Service</Link> | <Link to="/privacy-policy" className='TPlink_mob'>Privacy Policy</Link> |</p>
            </div>
        </footer>
    </div>;
};

export default MobFooter;
