import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    payers: [],
    plans_list: [],
    modal_view: false
}
export const UISlice = createSlice({
    name: 'UI',
    initialState: initialState,
    reducers: {
        updatePayers: (state, action: PayloadAction<any>) => {
            state.payers = action.payload
        },
        updatePlansList: (state, action: PayloadAction<any>) => {
            state.plans_list = action.payload
        },
        updateModalView: (state, action: PayloadAction<any>) => {
            state.modal_view = action.payload
        },
    }
})
export const UIActions = UISlice.actions

export default UISlice.reducer