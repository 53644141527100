import React from 'react'
import { Form } from 'react-bootstrap'

//@ts-ignore
const InputField = ({fieldValue,fieldLabel}) => {
  const storedTheme = sessionStorage.getItem("theme");

  return (
    <div>
        <Form.Group
                  className={"mb-3"}
                  controlId="formBasicInsurer"
                  aria-required="true"
                >
                  <Form.Label>{fieldLabel}</Form.Label>
                  <Form.Control
                    type="text"
                    className={
                        storedTheme !== 'dark' ? "input-width" : "input-width input_full_dark"}
                    defaultValue={fieldValue}
                    readOnly={true}
                    data-testid="writepermission"
                  />
                </Form.Group>
    </div>
  )
}

export default InputField