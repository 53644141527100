import React from 'react'
import { Image } from 'react-bootstrap';
import memberPortalLogo from "../../images/GMDlogo.svg";
import mmhd_logo from "../../images/mmhdWhiteT.png";

import './GMDlogo.scss'
const GMDwithoutDD = () => {
    return (
        <div>
            <div className="member-portal-login-GMD">
                {/* <img
                    src={memberPortalLogo}
                    alt="membpLogo"
                    data-testid="GMD_Logo"
                // className='mmhd_logo'
                /> */}
                <br />
                {/* <p className="logo-login-GMD"><i>Move</i>My<i>HealthData</i></p> */}
                <Image src={mmhd_logo} alt="MMHD_Logo" className="mmhd_logo" />
            </div>
        </div>
    )
}

export default GMDwithoutDD
