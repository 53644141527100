import React, { useCallback, useEffect, useState } from 'react'
import './LandingPage2.scss'
import {Container, Spinner } from 'react-bootstrap'
import LandingPageFooter from '../../components/Footer/LandingPageFooter/LandingPageFooter'
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks'
import { fetchAllPLansList, fetchNewPayers} from '../../functions/LandingPage/LandingPage_Funcs'
import PlanList2 from '../../components/PlansList/PlanList2'
import { useLocation } from 'react-router-dom'
import Switch from '@mui/material/Switch'
import ThreeStepModal from '../../components/Modals/ThreeStepModal/ThreeStepModal'
import CustomizedTreeView from '../../components/TreeView/TreeView'
import { UIActions } from '../../redux/feature/UISlice'
import '../../main.scss'
import './Landing_darktheme.scss'
import LandingNav from '../../components/LandingNavBar/LandingNav'
import RedirectModal from '../../components/Modals/RedirectModal/RedirectModal'
import { handleOauth, handlePostPayer } from '../../functions/LoginPage/LoginPageFuncs'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { Initial_LandindPageChanges, initial_search_change } from '../../constants/PageChanges'
import { CSR_redirectModal } from '../../constants/ModalChanges'

const LandingPage2 = () => {
    const dispatch = useAppDispatch()
    const UI = useAppSelector((state) => state.UI)

    const [socketUrl, setSocketURL] = useState('')
    const [urlMsg, setUrlMsg] = useState(
        {
            data: {
                initiation_time: new Date().toISOString(),
                end_time: '',
                customer_id: '',
                status:0
            },
            msg_event: 'url_action'
        }
    )
    const [clickingMsg, setClickingMsg] = useState(
        {
            data: {
                initiation_time: new Date().toISOString(),
                end_time: '',
                customer_id: '',
                status: 0
            },
            msg_event: 'clicking_url'
        }
    )

    //useState Variables
    const [searchInput, setSearchInput] = useState('')
    const [showToast, setShowToast] = useState(false)
    const [checked, setChecked] = useState(true)
    const [show, setShow] = useState(UI.modal_view ? false : true)

    const [spinner, setSpinner] = useState(true)
    const showSpinnerTrue = () => setSpinner(true)
    
    const [LandingPageVar, setLandingPageVar] = useState(Initial_LandindPageChanges)
    const [searchVar, setSearchVar] = useState(initial_search_change)
    const [CsrModalVar, setCsrModalVar] = useState(CSR_redirectModal)
    

    const handleCloseRedirect = () => setCsrModalVar({
        ...CsrModalVar,
        show_redirect_modal : false
    })
    const handleShowRedirect = () => setCsrModalVar({
        ...CsrModalVar,
        show_redirect_modal : true
    })
    
    useEffect(()=>{
        console.log(LandingPageVar,"++")
        },[LandingPageVar])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        setChecked(event.target.checked)
        event.target.checked === true ?
            setLandingPageVar({
                ...LandingPageVar,
                card_view : false,
                card_view_text : "Payer View"
            })
        :
            setLandingPageVar({
                ...LandingPageVar,
                card_view : true,
                card_view_text : "Plan View"
            })
    }

    const handleClose = () => {
        setShow(false)
        dispatch(UIActions.updateModalView(true))
    }
    const handleShow = () => {
        dispatch(UIActions.updateModalView(false))
        setShow(true)
    }

    const storedTheme = sessionStorage.getItem("theme")
    
    const location = useLocation()

    const {readyState, sendJsonMessage } = useWebSocket(socketUrl)
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState]

    const sendSocketMsg = useCallback((msg) => sendJsonMessage(msg), [sendJsonMessage])

    useEffect(() => {
        let customer_id = sessionStorage.getItem('customer_id')
        if (customer_id === '' || customer_id === null) {
            const path = window.location.pathname.split('/')
            customer_id = path[path.length - 2]
            sessionStorage.setItem('customer_uuid', customer_id)
            console.log("path",path);
            
        }
        console.log(customer_id)
        setSocketURL(`${process.env.REACT_APP_WEBSOCKET_URL}/${customer_id}/`)

        //socketmsg
        setClickingMsg((prevValue) => ({ ...prevValue, data: { ...prevValue.data, end_time: new Date().toISOString(), customer_id: customer_id as string,status:1 } }))
        setUrlMsg((prevValue) => ({ ...prevValue, data: { ...prevValue.data, end_time: new Date().toISOString(), customer_id: customer_id as string,status:1 } }))
        if (readyState === ReadyState.OPEN) {
            sendSocketMsg(urlMsg)
            sendSocketMsg(clickingMsg)
        }

    }, [readyState, location.pathname, sendSocketMsg])

    useEffect(() => {
        if(CsrModalVar.csr_payer_name!=="" && CsrModalVar.csr_payer_logo!=="") {
            handleClose()           
            handleShowRedirect()
            try{
                const res = handlePostPayer(CsrModalVar.csr_payer_name, setSpinner, setSpinner,setShowToast, setShow)
                console.log(res,"result");
                
                const payerLogo = `${process.env.REACT_APP_LOGO}/${CsrModalVar.csr_payer_logo}`
                localStorage.setItem('logo', payerLogo)
                handleOauth(showSpinnerTrue)
            }
            catch (ex){
                console.log(ex);
                setCsrModalVar({
                    ...CsrModalVar,
                    show_redirect_modal : false
                })
                
            }
        }
    }, [CsrModalVar.csr_payer_name, CsrModalVar.csr_payer_logo])
    useEffect(() => {
        // localStorage.clear();

        if (UI.payers.length === 0) {
            fetchNewPayers(dispatch,setLandingPageVar,LandingPageVar )
        }
        if (UI.plans_list.length === 0) {
            fetchAllPLansList(dispatch, setLandingPageVar,LandingPageVar,setCsrModalVar,CsrModalVar)
        }
        setLandingPageVar({
            ...LandingPageVar,
            show_new_Payers : true
        })
    }, [])

    return (
        <div className={storedTheme !== 'dark' ? 'landing_wrapper' : 'theme_wrapper'}>
            <LandingNav setLandingPageVar={setLandingPageVar} LandingPageVar={LandingPageVar} handleShow={handleShow} setSearchInput={setSearchInput} setSearchVar={setSearchVar} searchVar={searchVar}/>
            <div>
                <Container fluid>
                    {LandingPageVar.show_new_Payers ?
                        
                        <div className={'mid_content_wrapper'} data-testid='landing_page_cards'>
                            <div style={{ 'float': 'right' }}>
                                <Switch
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{LandingPageVar.card_view_text}
                            </div>
                            {LandingPageVar.card_view ? <>
                                {searchInput.length > 0 ? (
                                    <>
                                        <PlanList2 filteredItems={searchVar.filteredResults} />
                                    </>
                                ) : (
                                    <>
                                        <PlanList2 filteredItems={UI.plans_list} />

                                    </>
                                )}
                            </>
                                :
                                <>
                                    {searchInput.length > 0 ? (
                                        <>
                                            <CustomizedTreeView filteredItems={searchVar.filteredResults} />
                                        </>
                                    ) : (
                                        <>
                                            <CustomizedTreeView filteredItems={UI.plans_list} />

                                        </>
                                    )}
                                </>
                            }
                        </div>
                        :
                        <div className='spinner_wrapper'>
                                <Spinner variant='primary' animation='border' />
                            <p>{LandingPageVar.show_dashboard_content}</p>
                        </div>
                    }
                </Container>

            </div>
            <LandingPageFooter />
            {/* <HomeFooter /> */}
            <ThreeStepModal
                show={show}
                handleClose={handleClose}
                setShow={setShow} />
            <RedirectModal payername={CsrModalVar.csr_payer_name} showRedirect={CsrModalVar.show_redirect_modal} handleCloseRedirect={handleCloseRedirect} showToast={showToast} setShowToast={show} />
        </div>
    )
}

export default LandingPage2