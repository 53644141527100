import React from 'react';
import './LandingPageFooter.scss'
import { Link } from 'react-router-dom';
import mmhd_logo from '../../../images/mmhdWhiteT.png'
import { Image } from 'react-bootstrap';

const LandingPageFooter = () => {
    const storedTheme = sessionStorage.getItem("theme");
    return <div className={storedTheme != 'dark' ? 'footer_wrapper' : 'footer_wrapper footer_wrapper_dark'}>
        <footer data-testid='footer_landing'>
            <div className='left_end_footer'>
                <span className='left_end_text'>© <Image src={mmhd_logo} alt='mmhd_logo' className='landing_footer_mmhd' /></span>
            </div>
            <div className='right_end_footer'>
                <p className='TPLinks_para'>| <Link to='/terms-of-service' className='TPLinks'>Terms Of Service</Link> | <Link to='/privacy-policy' className='TPLinks'>Privacy Policy</Link> |  </p>
            </div>
        </footer>
    </div>;
};

export default LandingPageFooter;
