import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PatientState {
    fullName: string,
    dob: string,
    gender: string,
    address1: string,
    address2: string,
    email: string,
    phoneNumber: string,
    healthPlanChoice: string,
    insurer: string,
    healthPlanName: string,
    yourName: string,
    memberId: string,
    groupId: string,
    planId: string,
    subscriberId: string,
    payerWebsiteDetails: string,
    payerCustomerSupportDetails: string,
    payerRepresentativeDetails: string,
    dataCategories: string,
    optionalYourName: string,
    dateOfSignature: string,
    signatureUrl: string,
    dateTimeOfUpdate: string,
    taskId: string,
    resourceId: string,
}
const initialState: PatientState = {
    fullName: '',
    dob: '',
    gender: '',
    address1: '',
    address2: '',
    email: '',
    phoneNumber: '',
    healthPlanChoice: '',
    insurer: '',
    healthPlanName: '',
    yourName: '',
    memberId: '',
    groupId: '',
    planId: '',
    subscriberId: '',
    payerWebsiteDetails: '',
    payerCustomerSupportDetails: '',
    payerRepresentativeDetails: '',
    dataCategories: "Everything",
    optionalYourName: '',
    dateOfSignature: '',
    signatureUrl: '',
    dateTimeOfUpdate: '',
    taskId: '',
    resourceId: ''
}

export const patientSlice = createSlice({
    name: 'patient',
    initialState: initialState,
    reducers: {
        updateFullName: (state, action: PayloadAction<string>) => {
            state.fullName = action.payload
        },
        updateDob: (state, action: PayloadAction<string>) => {
            state.dob = action.payload
        },
        updateGender: (state, action: PayloadAction<string>) => {
            state.gender = action.payload
        },
        updateAddress1: (state, action: PayloadAction<string>) => {
            state.address1 = action.payload
        },
        updateAddress2: (state, action: PayloadAction<string>) => {
            state.address2 = action.payload
        },
        updateEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        updatePhoneNumber: (state, action: PayloadAction<string>) => {
            state.phoneNumber = action.payload
        },

        updateHealthPlanChoice: (state, action: PayloadAction<string>) => {
            state.healthPlanChoice = action.payload
        },
        updateInsurer: (state, action: PayloadAction<string>) => {
            state.insurer = action.payload
        },
        updateHealthPlanName: (state, action: PayloadAction<string>) => {
            state.healthPlanName = action.payload
        },
        updateYourName: (state, action: PayloadAction<string>) => {
            state.yourName = action.payload
        },
        updateMemberId: (state, action: PayloadAction<string>) => {
            state.memberId = action.payload
        },
        updateGroupId: (state, action: PayloadAction<string>) => {
            state.groupId = action.payload
        },
        updatePlanId: (state, action: PayloadAction<string>) => {
            state.planId = action.payload
        },

        updateSubscriberId: (state, action: PayloadAction<string>) => {
            state.subscriberId = action.payload
        },
        updatePayerWebsiteDetails: (state, action: PayloadAction<string>) => {
            state.payerWebsiteDetails = action.payload
        },
        updatePayerCustomerSupportDetails: (state, action: PayloadAction<string>) => {
            state.payerCustomerSupportDetails = action.payload
        },
        updatePayerRepresentativeDetails: (state, action) => {
            state.payerRepresentativeDetails = action.payload
        },
        updateDataCategories: (state, action: PayloadAction<string>) => {
            state.dataCategories = action.payload
        },
        updateOptionalYourName: (state, action: PayloadAction<string>) => {
            state.optionalYourName = action.payload
        },
        updateDateOfSignature: (state, action: PayloadAction<string>) => {
            state.dateOfSignature = action.payload
        },
        updateSignatureUrl: (state, action: PayloadAction<string>) => {
            state.signatureUrl = action.payload
        },
        updateDateTimeOfUpdate: (state, action: PayloadAction<string>) => {
            state.dateTimeOfUpdate = action.payload
        },
        updateTaskId: (state, action: PayloadAction<string>) => {
            state.taskId = action.payload
        },
        updateResourceId: (state, action: PayloadAction<string>) => {
            state.resourceId = action.payload
        },

    }
})

export const patientActions = patientSlice.actions
export default patientSlice.reducer