import { useEffect, useMemo, useState } from "react"
import "./App.css"

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import GetClinicalData from "./pages/GetClinicalData/GetClinicalData"
import PlanCoverage from "./pages/PlanCoverage/PlanCoverage"
import LoginPage from "./pages/LoginPage/LoginPage"
import ConfirmDetails from "./pages/ConfirmDetails/ConfirmDetails"
import PickPlans from "./pages/PickPlans/PickPlans"
import ConsentPage from "./pages/ConsentPage/ConsentPage"
import TermsAndCondition from "./pages/TermsAndConditions/TermsAndCondition"
import PrivacyAndPolicy from "./pages/PrivacyPolicy/PrivacyAndPolicy"
import PageNotFound from "./pages/PageNotFound/PageNotFound"
import { useAppSelector } from "./redux/app/hooks"
import PrivateRoute from "./components/PrivateRoute/PrivateRoute"
import AboutUs from "./pages/AboutUs/AboutUs"
import LandingPage2 from "./pages/LandingPage/LandingPage2"
import HomeMember from "./pages/HomePage/HomeMember"
import HomePayor from "./pages/HomePage/HomePayor"
import SiteMap from "./pages/SiteMap/SiteMap"
import CarinBBTerms from "./pages/CarinBBTerms/CarinBBTerms"
import DataViewer from "./pages/DataView/DataView"
import LoginPayerPage from "./pages/LoginPage/LoginPayerPage"
function App() {
    const UI = useAppSelector((state) => state.UI)

    const myValue = window.location.href
    const accessTokenValue = myValue?.split("?")[1]?.slice(13)

    useEffect(() => {
        localSetter(accessTokenValue)
    }, [accessTokenValue])
    const localSetter = (value: any) => {
        return sessionStorage.setItem("auth", value)
    }

    const [isAuth, SetIsAuth] = useState(false)

    const providerValue16 = useMemo(
        () => ({ isAuth, SetIsAuth }),
        [isAuth, SetIsAuth]
    )
    const [payerlist, Setpayerlist] = useState()

    const providerValue1 = useMemo(
        () => ({ payerlist, Setpayerlist }),
        [payerlist, Setpayerlist]
    )
    var payerist
    var obtlist

  const supported_payers_list =
    localStorage.getItem("payers_list") !== null
  //@ts-ignore
      ? JSON.parse(localStorage.getItem("payers_list"))
      : [];

  const routeComponents = supported_payers_list.map((val:any) => (
    <Route
      exact
      path={"/" + val.slug}
      component={() => (
        <LoginPage
        //@ts-ignore
          clientName={val.name}
          clientLogo={""}
          linkTo={"/" + val.slug}
        />
      )}
      key={val.id}
    />
  ));
    const get_payer_list = () => {
        obtlist = (localStorage.getItem('payers_list'))
        if (obtlist === null) {
            obtlist = localStorage.getItem('payers_list')
            payerist = []
        }
        else {
            obtlist = localStorage.getItem('payers_list')
            //@ts-ignore
            payerist = JSON.parse(obtlist)
        }
    }
    get_payer_list()

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomeMember} />
        <Route exact path="/login/:slug" component={LoginPayerPage}/>
        <Route exact path="/payor" component={HomePayor} />
        <Route exact path="/sitemap" component={SiteMap} />
        <Route exact path="/dashboard" component={LandingPage2} />
        <Route
          exact
          path="/redirect/dashboard/api/ws/:uuid"
          component={LandingPage2}
        />
        <Route exact path="/carin-code-of-conduct" component={CarinBBTerms} />
        {/* <Route exact path="/view_data" component={DataViewer} /> */}
        <PrivateRoute path="/confirm_details">
          <ConfirmDetails />
        </PrivateRoute>
        {/* <PrivateRoute path="/view_data">
          <DataViewer />
        </PrivateRoute> */}
        <PrivateRoute path="/pick_plans">
          <PickPlans />
        </PrivateRoute>
        <PrivateRoute path="/plan_coverage">
          <PlanCoverage />
        </PrivateRoute>
        <PrivateRoute path="/get_clinical_data">
          <GetClinicalData />
        </PrivateRoute>
        <PrivateRoute path="/consent">
          <ConsentPage />
        </PrivateRoute>
        <Route exact path="/terms-of-service" component={TermsAndCondition} />
        <Route exact path="/privacy-policy" component={PrivacyAndPolicy} />
        <Route exact path="/about-us" component={AboutUs} />
        {routeComponents}
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
}

export default App
