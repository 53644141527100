import React from "react";

export const getPayer = () => {
    const payer = localStorage.getItem("payers_list")
    if (payer == null) {
        return []
    }
    else {
        return JSON.parse(payer)
    }
}
//@ts-ignore
export const searchItems = (searchValue, setSearchInput, searchList,setSearchVar,searchVar) => {
    setSearchInput(searchValue)
    setSearchVar({
        ...searchVar,
        search_input : searchValue
    })
    console.error(searchValue, searchList)
    if (searchValue !== '') {
        const filteredData = searchList.map((item: any) => ({
            ...item, plans: item.plans.filter(({ contract_number, legal_entity_name }: any) => {
                const planEntires = Object.entries({ contract_number, legal_entity_name })
                const f = planEntires.filter(entry => {
                    console.log(entry);

                    //@ts-ignore
                    return entry[1] && entry[1].toLowerCase().includes(searchValue.toLowerCase())
                })
                if (f.length > 0) {
                    return f
                }

            })
        })).sort((a: { plans: string | any[]; }, b: { plans: string | any[]; }) => {
            if (a.plans.length < b.plans.length) {
                return 1
            }
            else if (a.plans.length > b.plans.length) {
                return -1
            }
            else {
                return 0
            }
        })

        console.error("filtered", filteredData);

        //@ts-ignore
        setSearchVar({
            ...searchVar,
            filteredResults : filteredData
        })
    }
    else {
        //@ts-ignore
        setSearchVar({
            ...searchVar,
            filteredResults : searchList
        })
    }
}