import React from 'react'
import { Image } from 'react-bootstrap';
import memberPortalLogo from "../../images/GMDlogo.svg";
import mmhd_logo from "../../images/mmhdWhiteT.png";
import DropDown from '../DropDown/DropDown';
import './GMDlogo.scss'
const GMDlogo = () => {
  return (
    <div>
      <div className="member-portal-login-GMD">
        {/* <img
          src={memberPortalLogo}
          alt="membpLogo"
          data-testid="GMD_Logo"
        /> */}
        <div className='drop_down'>
          <DropDown />
        </div>
        <br />
        {/* <p className="logo-login-GMD"><i>Move</i>My<i>HealthData</i></p> */}
        <Image src={mmhd_logo} alt="MMHD_Logo" className="mmhd_logo" data-testid="GMD_Logo" />
      </div>
    </div>
  )
}

export default GMDlogo
