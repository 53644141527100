import React from 'react'
import './SummaryBox.scss'
import { Badge, Table } from 'react-bootstrap'

//@ts-ignore
const SummaryBox = ({ resrcs }) => {
    let listResrc :any
    const storedTheme = sessionStorage.getItem("theme");

    return (
        <div>
            <div className='summary_list'>
                {resrcs === null ? null :
                    <>
                        <Table bordered responsive="sm">
                            <tr>
                                <td className={storedTheme !== 'dark' ? 'resrc_heads' : 'resrc_heads resrc_heads_dark'}>
                                    Total Records Moved
                                </td>
                                <td className='badges_count'>
                                    <Badge bg="primary" pill>
                                        {resrcs.TotalRecords.TotalCount === null ? "" : resrcs.TotalRecords.TotalCount}
                                    </Badge>
                                </td>
                                <td className={storedTheme !== 'dark' ? 'record_suffix' : 'record_suffix resrc_heads_dark'}>
                                    {resrcs.TotalRecords.TotalCount === 0 ? " no " : " "}
                                    record
                                    {resrcs.TotalRecords.TotalCount === 1 ? "" : "s"}
                                </td>
                            </tr>
                            {resrcs.TotalRecords.Resources ? resrcs.TotalRecords.Resources.map((element: any) => (
                                <>
                                    <tr>
                                        <td className={storedTheme !== 'dark' ? 'resrc_heads' : 'resrc_heads resrc_heads_dark'}>
                                            {element.Type}
                                        </td>
                                        <td className='badges_count'>
                                            <Badge bg="primary" pill>
                                                {element.TotalCount}
                                            </Badge>
                                        </td>

                                        <td className={storedTheme !== 'dark' ? 'record_suffix' : 'record_suffix resrc_heads_dark'}>
                                            {element.TotalCount === 0 ? " no " : " "}record
                                            {element.TotalCount === 1 || 0 ? "" : "s"}
                                        </td>
                                    </tr>

                                    {element.resource.length !== 0 && element.Type !== "Patient" ?
                                        <tr>
                                            <td colSpan={3} className={storedTheme !== 'dark' ? 'rescrc_list' : 'rescrc_list resrc_heads_dark'}>
                                                {element.resource.map((key: { type: string; count: string; }) => (
                                                    listResrc = key.type + ": " + key.count + ", "
                                                ))}
                                            </td>
                                        </tr>
                                        : null}
                                </>
                            )) : null}
                        </Table>
                    </>
                }
            </div>
        </div>
    )
}

export default SummaryBox