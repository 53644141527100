import React from "react";
import { Modal, Image, Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";

//@ts-ignore
const Modal2 = ({ show2, handleClose2, processingimg, handleLogOut, modal2Text, loader, btnDisable, remainingSeconds, linkTo }) => {
  const storedTheme = sessionStorage.getItem("theme");

  return (
    <div>
      <Modal
        show={show2}
        onHide={handleClose2}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={storedTheme == 'dark' ? "modal3_dark" : "modal3_light"}
      >
        <Modal.Body className="last2_modal">
          <Image src={processingimg} alt="processingimg" />
          <br />
          {loader ?
            <Spinner variant="primary" animation="border" />
            : null}
          {modal2Text}
          <br />
          You will be automatically logged out in{" "}
          {remainingSeconds.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
          })}{" "}
          seconds. To Logout immediately, click the button below.
          <br />
          <Button
            variant="primary"
            className={storedTheme != 'dark' ? "last2-btn btn_blue" : "last2-btn dark_btn_3"}
            disabled={btnDisable}
            onClick={handleLogOut}
            data-testid="Log_out_btn_GCD"
          >
            Ok, Log Me Out
            (
            {remainingSeconds.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })})
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Modal2;
