import { getNewpayerlist } from "../../apis/apis";
import { appInsights } from "../../components/AppInsights/appInsights";

export const handleHmnyClient = (clientName: string, sethmnyClient: (arg0: boolean) => void) => {
    if (clientName === "HIGHMARK") {
        sethmnyClient(true);
    }
}
//@ts-ignore
export const handlePostPayer = async (clientName: any, setSpinner, setBtndisable, setShowToast, setShow) => {
    sessionStorage.setItem("client", clientName)
    appInsights.trackEvent({ "name": `User selected ${clientName} as new payer` })
    try {
        const result = await getNewpayerlist(clientName, "confirm_details");
        console.log(clientName);

        setSpinner(false)
        setBtndisable(false)
        appInsights.trackEvent({ "name": `${clientName} posted to Oauth` })
    }
    catch (error) {
        setShowToast(true)
        setShow(false)
        appInsights.trackEvent({ "name": `Got error ${error} while posting ${clientName} to Oauth` })
        //@ts-ignore
        appInsights.trackException({ exception: error })
    }
}

//@ts-ignore
export const handleOauth = (showSpinnerTrue) => {
    showSpinnerTrue()
    appInsights.trackEvent({ name: "User accepted T&C and going oauth page of selected plan or payer" })
    window.location.href = `${process.env.REACT_APP_OAUTH_NEW_PAYER}/auth/idp2`

}

export const handleCancel = (handleClose: () => void) => {
    handleClose()
    appInsights.trackEvent({ name: "Member cancels T&C" })

}