import React, { useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { handleLogout } from '../../functions/LogOut/Logout'
import './DropDown.scss'
import '../../pages/LandingPage/Landing_darktheme.scss'

const DropDown = () => {
    let history = useHistory();
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <div>
            <DropdownButton id="dropdown-basic-button" align={{ lg: 'start' }} title="Logout" className='log_out_dropdown'>
                <Dropdown.Item onClick={() => handleLogout(history)} className='dark_btn_3'>Log Out</Dropdown.Item>
                {/* <Dropdown.Item onClick={() => handleToLoginPage()}>Login with different credentials</Dropdown.Item> */}
            </DropdownButton>
        </div>
    )
}

export default DropDown