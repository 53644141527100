import React from 'react'
import { OverlayTrigger, Tooltip, Image, Button } from 'react-bootstrap'
import InfoIcon from '@mui/icons-material/Info';
import './PlanCoverage.css'
//@ts-ignore
const InfoOverlay = ({ label, ireq, info }) => {
    return (
        <div>
            {label}
            <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="button-tooltip-2">{info}</Tooltip>}
            >
                {({ ref, ...triggerHandler }) => (
                    // <Button
                    //     variant="light"
                    //     {...triggerHandler}
                    //     className="d-inline-flex align-items-center"
                    // >

                        <InfoIcon
                            ref={ref}
                            
                            {...triggerHandler}
                            className='info_icon'
                        />
                    // </Button>
                )}
            </OverlayTrigger>
        </div>
    )
}

export default InfoOverlay
