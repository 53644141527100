import { Link } from 'react-router-dom'
import '../../../pages/HomePage/HomePage.scss'

import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

import mmhdLogo from '../../../pages/HomePage/img/mmhd-logo.png'
import mmhdLogoDark from '../../../pages/HomePage/img/mmhd-logo-dark.png'


var logo = mmhdLogo;

const storedTheme = sessionStorage.getItem("theme");

if (storedTheme === 'dark') {
    logo = mmhdLogoDark;
}
const HomeFooter = () => {

    return (
        <div>
            <footer id="footer" className="footer">

                <div className="footer-top">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-5 col-md-12 footer-info">
                                <a href="#" className="logo d-flex align-items-center">
                                    <img src={logo} alt="" />
                                </a>
                                <p>A Product By Onyx</p><br />
                                <p>Onyx Technology LLC is a healthcare interoperability solutions provider.</p>
                                <div className="social-links mt-3">
                                    <a href="https://twitter.com/OnyxHealth_Tech" target="_blank" className="twitter"><TwitterIcon className='icons-footer' /></a>
                                    <a href="https://www.linkedin.com/uas/login?session_redirect=%2Fcompany%2F43215528%2F" target="_blank" className="linkedin" rel="noreferrer"><LinkedInIcon className='icons-footer' /></a>
                                    <a href="https://www.youtube.com/channel/UCOTcNXnOD0MfjvqqSll9XiA/featured" target="_blank" className="facebook" rel="noreferrer"><YouTubeIcon className='icons-footer' /></a>
                                </div>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>Legal</h4>
                                <ul>
                                    <li><ChevronRightIcon className='icons-footer' /><Link to={'/terms-of-service'}> <a target="_blank">Terms Of Service</a></Link></li>
                                    <li><ChevronRightIcon className='icons-footer' /><Link to={'/privacy-policy'}> <a target="_blank">Privacy Policy</a></Link></li>
                                    <li><ChevronRightIcon className='icons-footer' /><Link to={{ pathname: '/carin-code-of-conduct' }}> <a target="_blank">CARIN Code of Conduct</a></Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>Also Visit</h4>
                                <ul>
                                    <li><ChevronRightIcon className='icons-footer' /> <a href="https://portal.safhir.io/" target="_blank">SAFHIR</a></li>
                                    <li><ChevronRightIcon className='icons-footer' /> <a href="https://www.onyxhealth.io/" target="_blank">Onyx</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>Contact Us</h4>
                                <ul>
                                    <li><LocationOnIcon className='icons-footer' />{process.env.REACT_APP_COMPANY_ADDRESS}</li>
                                    <li><MarkunreadMailboxIcon className='icons-footer' />{process.env.REACT_APP_COMPANY_MAIL_ADDRESS}</li>
                                    <li><PhoneIcon className='icons-footer' />410-782-0476</li>
                                    <li><MailOutlineIcon className='icons-footer' />info@onyxhealth.io</li>
                                    <li><AccessTimeIcon className='icons-footer' />Monday - Friday<br />9:00AM - 05:00PM</li>
                                </ul>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Onyx</span></strong>. All Rights Reserved
                    </div>
                </div>
            </footer>
        </div>
    );
}


export default HomeFooter