import React from 'react'

// React Router components
import { Redirect, Route, useHistory, useLocation } from 'react-router'
// import { useSearchParams } from 'react-router-dom'

// Custom Components
import { authActions } from '../../redux/feature/AuthSlice'
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks'

interface PrivateRouteProps {
    children?: JSX.Element
    path: string
}

export default function PrivateRoute({
    children,
    path
}: PrivateRouteProps): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dispatch = useAppDispatch()
    const { search } = useLocation()



    const auth = useAppSelector((state) => state.auth)

    if (sessionStorage.getItem('auth_token')) {
        dispatch(
            authActions.setAuthToken(
                sessionStorage.getItem('auth_token') as string
            )
        )
    } else {
        const authToken = new URLSearchParams(search).get("access_token")
        if (authToken) {
            sessionStorage.setItem('auth_token', authToken)
            dispatch(authActions.setAuthToken(authToken))
        }

    }

    return auth.authToken ? <Route path={path} render={() => children} /> : <Redirect to="/" />
}
