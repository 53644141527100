import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "./ConsentPage.css";
import { useHistory } from "react-router";
import processingimg from "../../images/Processing.png";
import tick from "../../images/tick.svg";
import errorImage from "../../images/error.svg"
import { appInsights } from "../../components/AppInsights/appInsights";

import Logo from "../../components/Logo/Logo";
import ConsentModal1 from "../../components/Modals/ConsentModals/ConsentModal1";
import ConsentModal2 from "../../components/Modals/ConsentModals/ConsentModal2";
import { v4 as uuidv4 } from 'uuid';
import Bar1 from "../../components/SideBar/HorizontalStepper/Bar1";
import MobFooter from "../../components/Footer/MobFooter/MobFooter";
import { handleClick, handleShowNClose, setBtnTxtFunc } from "../../functions/ConsentPage/ConsentFuncs";
import SideBar from "../../components/SideBar/OtherSideBar/SideBar";
import { handleLogout } from "../../functions/LogOut/Logout";
import { GetSinceDateValues, postDataToOneId } from "../../apis/apis";
import useWebSocket from "react-use-websocket";
import { socketUrl } from "../../websocket/websocket";
import DateSelectionModal from "../../components/Modals/ConsentModals/DateSelectionModal";


export const getPatientData = () => {
  return JSON.parse(localStorage.getItem("patientData") || '{}');

};

function ConsentPage() {
  const client_local = sessionStorage.getItem("client");

  appInsights.trackEvent({ name: "Member successfully logs into Old Payer's IDP." })
  var uuid = uuidv4()
  const formSubmitHandler = (e: any) => {
    e.preventDefault();
  };


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [processed, setProcessed] = useState("Processing...");
  const [processedContent, setProcessedContent] = useState(
    "Kindly wait while we are processing data for you."

  );
  const [waitingcontent, setWaitingcontent] = useState("This can take upto 1 minute. Please be patient.")

  const [loading, setLoading] = useState("Loading..");
  const [ImageTick, setImageTick] = useState(false);
  const [loadingImage, setLoadingImage] = useState(true);
  const [errorImg, seterrorImg] = useState(false);

  const [responseStatus, SetResponseStatus] = useState(false);
  const [responseStatusId, SetResponseStatusId] = useState(false);

  const [uuidresponse, setUuidresponse] = useState("");
  const [uuidgenerate, setUuidgenerate] = useState("");
  const [showuuid, setShowuuid] = useState(false)
  const [modalmsg, setModalmsg] = useState("")
  const [remainingSeconds, setRemainingSeconds] = useState(15)

  const [disableBtn, setDisableBtn] = useState(false)
  const [btnTxt, setBtnTxt] = useState("Accept & Proceed")

  const [task_id, setTaskId] = useState("")


  let history = useHistory();
  let customer_uuid = sessionStorage.getItem("customer_uuid")
  const { sendJsonMessage } = useWebSocket(socketUrl);
  const msg = {
    data: {
      initiation_time: new Date().toISOString(),
      end_time: new Date().toISOString(),
      customer_id: customer_uuid,
      status: 1
    },
    msg_event: 'logout'
  }
  //socketmsg
  const msg2 = {
    data: {
      initiation_time: new Date().toISOString(),
      end_time: new Date().toISOString(),
      customer_id: customer_uuid,
      status: 1
    },
    msg_event: 'moving_health_data'
  }
  const msg3 = {
    data: {
      initiation_time: new Date().toISOString(),
      end_time: new Date().toISOString(),
      customer_id: customer_uuid,
      status: -1
    },
    msg_event: 'moving_health_data'
  }
  const handleClickSendMessage2 = useCallback(() => {
    console.log('socketsuccess');
    sendJsonMessage(msg2)
  }, [msg2]);
  const handleClickSendMessage3 = useCallback(() => {
    console.log('socketfailed');
    sendJsonMessage(msg3)
  }, [msg3]);

  const handleClickSendMessage = useCallback(() => sendJsonMessage(msg), [msg]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let intervalId: any = 0
    if (show2) {
      intervalId = setInterval(() => {
        setRemainingSeconds((prevState: number) => (prevState -= 1))
        if (remainingSeconds === 1) {
          handleClickSendMessage()
          handleLogout(history)
        }
      }, 1000)
    } else if (!show2 && remainingSeconds !== 0 && intervalId !== null) {
      clearInterval(intervalId)
    }
    return () => clearInterval(intervalId)
  }, [remainingSeconds, show2])

  const [btnVariant, setbtnVariant] = useState("primary");

  const handleBtnVariant = () => {
    setbtnVariant("secondary")
  }
  const storedTheme = sessionStorage.getItem("theme");
  window.history.forward();

  let newpayerfhirId = localStorage.getItem("newpayerfhirId")
  let patData = getPatientData()
  let oldpayer = sessionStorage.getItem("old_payer_name")
  let newpayer = sessionStorage.getItem("client")

  const [dataSelectModal, setDataSelectModal] = useState(false)

  const handleCloseSelectModal = () => setDataSelectModal(false)

  const dataSelectVar = {
    'oneIdRecordStatus': false,
    'spinner': true,
    'loading_text': 'Kindly wait while we check whether you are our existing user... \n If not the processing of data will start immediately after this.',
    'btn_style': 'changed_get_data_btn',
    'data_fetched_text': '',
    'data': 'new',
  }

  const handleGetSinceData = async () => {
    try {
      //@ts-ignore
      const res = await GetSinceDateValues(newpayer, oldpayer)
      var OneIdRecordStatus = res.data.OneIdRecordStatus;
      if (OneIdRecordStatus === true) {
        setInitialState({
          ...initialState,
          'oneIdRecordStatus': true,
          'spinner': false,
          'loading_text': '',
          'data_fetched_text': `${res.data.last_Updated}`
        })
      }
      else {
        setInitialState({
          ...initialState,
          'oneIdRecordStatus': false,
          'spinner': false,
          'loading_text': 'No data found',
          'btn_style': 'get_data_btn_all',
          'data': 'all'
        })
        handleClick(setUuidresponse, uuid, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, handleShow, getPatientData, setBtnTxt, handleClickSendMessage2, handleClickSendMessage3, setTaskId)
      }
    } catch (error) {

    }
  }

  const [initialState, setInitialState] = useState(dataSelectVar)


  console.log(";;", initialState);
  // useEffect(() => {
  //   if(Object.keys(patData).length!==0){
  //     postDataToOneId(newpayerfhirId,patData.fullName,patData.phoneNumber,client_local,oldpayer,patData.email)
  //   }
  // }, [client_local])

  console.log(task_id);


  return (
    <Container fluid className={storedTheme !== 'dark' ? "page-wrapper" : "page-wrapper page-wrapper_dark"}>
      <Row className={storedTheme !== 'dark' ? "content-wrapper" : "content-wrapper page-wrapper_dark"}>
        <Col lg={4} className={storedTheme !== 'dark' ? "left-bar" : "left-bar left-bar_dark"}>
          <SideBar curr_path={2} msg_top="One more step!..." />
        </Col>
        <Col lg={8} xs={12} sm={12} className={storedTheme !== 'dark' ? "right-bar" : "right-bar right_bar_dark"}>
          <Logo />
          <div className="steps-wrapper-991">
            <Bar1 actstep={2} />
          </div>

          <div className="col-10">
            <Form
              className="form-complete"
              onSubmit={formSubmitHandler}
              data-testid="form"
            >
              <p className="step-info-991">
                Done!
                <br />
              </p>
              <Form.Text className="text-muted">
                By clicking on <b>ACCEPT AND PROCEED</b> I authorize
                <ul>
                  <li>Retrieval of my clinical data from my prior health plan  </li>
                  <li>Storing of this clinical data by {client_local}</li>
                  <li>
                    Analysis of this clinical data to, where necessary, provide me with recommendations to manage my health
                  </li>
                </ul>
              </Form.Text>
              <Row className="g-2 btns-down">
                <Button
                  variant={btnVariant}
                  type="submit"
                  className={storedTheme !== 'dark' ? "accept-btn" : "accept-btn dark_btn_3"}
                  onClick={() => {
                    // handleClick(setUuidresponse, uuid, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, handleShow, getPatientData, setBtnTxt,handleClickSendMessage2,handleClickSendMessage3);
                    // setDisableBtn(true);
                    // setBtnTxt("Processing...");
                    setBtnTxtFunc(setBtnTxt, setDataSelectModal, setDisableBtn)
                    handleBtnVariant()
                    // setDataSelectModal(true)
                    handleGetSinceData()
                  }}
                  data-testid='accept_btn'
                  disabled={disableBtn}
                >
                  {btnTxt}
                </Button>

              </Row>
              <ConsentModal1
                show={show}
                handleClose={handleClose}
                loadingImage={loadingImage}
                tick={tick}
                processed={processed}
                processedContent={processedContent}
                handleShow2={handleShow2}
                responseStatus={responseStatus}
                responseStatusId={responseStatusId}
                loading={loading}
                ImageTick={ImageTick}
                errorImage={errorImage}
                errorImg={errorImg}
                handleShowNClose={() => handleShowNClose(handleShow2, handleClose)}
                uuidresponse={uuidresponse}
                uuidgenerate={uuidgenerate}
                showuuid={showuuid}
                waitingcontent={waitingcontent}
                taskId={task_id}
              />

              <ConsentModal2
                show2={show2}
                handleClose2={handleClose2}
                processingimg={processingimg}
                handelLogOut={() => handleLogout(history)}
                modalmsg={modalmsg}
                remainingSeconds={remainingSeconds}
              />
              <DateSelectionModal dataSelectModal={dataSelectModal} handleclose={handleCloseSelectModal} handleDataProcessing={() => handleClick(setUuidresponse, uuid, SetResponseStatus, SetResponseStatusId, setProcessed, setProcessedContent, setLoading, setLoadingImage, setImageTick, setWaitingcontent, setModalmsg, setShowuuid, seterrorImg, setUuidgenerate, handleShow, getPatientData, setBtnTxt, handleClickSendMessage2, handleClickSendMessage3, setTaskId)} initialState={initialState} />
            </Form>
          </div>
        </Col>
      </Row>
      <MobFooter />
    </Container>
  );
}

export default ConsentPage;

