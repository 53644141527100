import React from 'react'
import './TOSNPPcomp.scss'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import DeskFooterLogin from '../Footer/DeskFooter/DeskFooterLogin'
import MobFooter from '../Footer/MobFooter/MobFooter'
import GMDlogo from '../Logo/GMDlogo'
import LoginSideBar from '../SideBar/LoginSideBar/LoginSideBar'
import { gotoPreviousPage } from '../../functions/ToSNPP/PrivacyPolicyFuncs'
import { useHistory } from 'react-router'
import GMDwithoutDD from '../Logo/GMDwithoutDD'

//@ts-ignore
const ToSNPPcomp = ({ showSpinner, showTnc, Tnc }) => {
    let history = useHistory()
    var back = "<--";
    const storedTheme = sessionStorage.getItem("theme");

    return (
        <div><Container fluid className={storedTheme != 'dark' ? "page-wrapper-login" : "page-wrapper-login page-wrapper-login_dark"}>
            <Row className={storedTheme != 'dark' ? "content-wrapper-login" : "content-wrapper-login page-wrapper_dark"}>
                <Col lg={4} className={storedTheme != 'dark' ? "left-bar-login" : "left-bar-login left-bar-login_dark"}>
                    <GMDwithoutDD />
                    <LoginSideBar />
                    <DeskFooterLogin />

                </Col>
                <Col lg={8} xs={12} sm={12} className={storedTheme != 'dark' ? "right-bar-login" : "right-bar-login right_bar_dark"}>
                    <div>
                        <div>
                            <Button variant='primary' className='back_button' onClick={() => gotoPreviousPage(history)} data-testid="back_button">{back} Back</Button>
                        </div>
                        <br />
                        {showSpinner ?
                            <div className='terms_right_items'>
                                <Spinner animation={'border'} variant='primary' />

                                <p>Loading...</p>
                            </div>
                            : null}
                        {showTnc ?
                            <div className='scroll_bar' data-testid="tnc_container"><ReactMarkdown>{Tnc}</ReactMarkdown></div>
                            : null}
                    </div>
                </Col>
            </Row>
            <MobFooter />
        </Container>
        </div>
    )
}

export default ToSNPPcomp