import React from 'react'
import { Row } from 'react-bootstrap'
import mobileImage from "../../../images/MobileImg.svg";
import mobileImage_dark from "../../../images/MobileImg_dark.svg";


const LoginSideBar = () => {
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <div>
            <div className="heading-login">
                <p>
                    {" "}
                    Retrieve Health Data <br />
                    with<span style={{ color: "#e4c413" }}> only 3 Steps!</span>
                </p>
            </div>
            <Row className="heading-para-login" data-testid="subHeading">
                <p>
                    Use {process.env.REACT_APP_NAME} to bring your clinical health records from your
                    previous Health Plan.
                </p>
            </Row>
            <div>
                <img src={storedTheme != 'dark' ? mobileImage : mobileImage_dark} alt="mobileImage" className="mobile-icon" />
            </div>

        </div>
    )
}

export default LoginSideBar
