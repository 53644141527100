import { useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import "./PlanCoverage.css";
import { useHistory, withRouter } from "react-router";
import ireq from "../../images/iRequired.png";

import { PatientState } from "../../redux/feature/patientSlice";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import Logo from "../../components/Logo/Logo";
import InfoOverlay from "./InfoOverlay";

import { useForm } from "react-hook-form";
import Bar1 from "../../components/SideBar/HorizontalStepper/Bar1";
import MobFooter from "../../components/Footer/MobFooter/MobFooter";
import { handleChangeGroupId, handleChangeMemberId, handleChangePlanId, handleChangeSubscriberId, handleChangeYourName, handleupdateHealthPlanName, handleupdateInsurer } from "../../functions/PlanCoverage/PlanCoverageFuncs";
import SideBar from "../../components/SideBar/OtherSideBar/SideBar";
function PlanCoverage() {
  const {
    setValue,
  } = useForm<PatientState>();
  const patient = useAppSelector((state) => state.patient);

  useEffect(() => {
    setValue("insurer", patient.insurer);
  });
  const dispatch = useAppDispatch();

  let history = useHistory();
  const formSubmitHandler = (e: any) => {
    e.preventDefault();
  };

  var iInsurer = "New Insurer Name";
  var iHealthPlanName = "New Health Plan Name";
  var iMemberId = "Member Id for new Health Plan";
  var iSubscriberId = "Subscriber Id for new Health Plan";
  var iGroupId = "Group Id for new Health Plan";
  var iPlanId = "Plan Id for new Health Plan";
  const storedTheme = sessionStorage.getItem("theme");

  return (
    <Container fluid className={storedTheme !== 'dark' ? "page-wrapper" : "page-wrapper page-wrapper_dark"}>
      <Row className={storedTheme !== 'dark' ? "content-wrapper" : "content-wrapper page-wrapper_dark"}>
        <Col lg={4} className={storedTheme !== 'dark' ? "left-bar" : "left-bar left-bar_dark"}>
          <SideBar curr_path={1} msg_top="Welcome! first things first..." />

        </Col>
        <Col lg={8} xs={12} sm={12} className={storedTheme !== 'dark' ? "right-bar" : "right-bar right_bar_dark"}>
          <Logo />
          <div className="steps-wrapper-991">
            <Bar1 actstep={1} />
          </div>

          <Form
            className="form-complete"
            onSubmit={formSubmitHandler}
            data-testid="form"
          >
            <p className="step-info-991">
              How to get your Health Data
              <br />
            </p>
            <Form.Text className="text-muted">
              Prior Health Plan coverage
            </Form.Text>
            <p>Please provide your prior coverage details and we will see if we can add your prior health plan to our list of connected payers. Fill in as much information as possible.</p>
            <Form.Group
              className="mb-3"
              controlId="formBasicInsurer"
              aria-required="true"
            >
              <Form.Label>
                <InfoOverlay label="Insurer" ireq={ireq} info={iInsurer} />{" "}
              </Form.Label>
              <Form.Control
                type="text"
                className={storedTheme !== 'dark' ? "input-width" : "input-width input_full_dark"}
                onChange={(e) => handleupdateInsurer(e, dispatch)}
                value={patient.insurer}
                data-testid='insurer'
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="formBasicHealthPlan"
              aria-required="true"
            >
              <Form.Label>
                <InfoOverlay
                  label="Health Plan Name"
                  ireq={ireq}
                  info={iHealthPlanName}
                />
              </Form.Label>
              <Form.Control
                type="text"
                className={storedTheme !== 'dark' ? "input-width" : "input-width input_full_dark"}
                onChange={(e) => handleupdateHealthPlanName(e, dispatch)}
                value={patient.healthPlanName}
                required
                data-testid='healthplan'
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicYourName">
              <Form.Label>Your Name (if different)</Form.Label>
              <Form.Control
                type="text"
                className={storedTheme !== 'dark' ? "input-width" : "input-width input_full_dark"}
                onChange={(e) => handleChangeYourName(e, dispatch)}
                value={patient.yourName}
                data-testid='name'
              />
            </Form.Group>
            <Row className="g-2">
              <Col>
                <Form.Group
                  className="mb-3 left-input"
                  controlId="formBasicMemberID"
                >
                  <Form.Label>
                    <InfoOverlay
                      label="Member ID"
                      ireq={ireq}
                      info={iMemberId}
                    />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className={storedTheme !== 'dark' ? "input-width-small" : "input-width-small input_full_dark"}
                    onChange={(e) => handleChangeMemberId(e, dispatch)}
                    value={patient.memberId}
                    data-testid='memberId'
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicSubscriberID">
                  <Form.Label>
                    <InfoOverlay
                      label="Subscriber ID"
                      ireq={ireq}
                      info={iSubscriberId}
                    />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className={storedTheme !== 'dark' ? "input-width-small" : "input-width-small input_full_dark"}
                    onChange={(e) => handleChangeSubscriberId(e, dispatch)}
                    value={patient.subscriberId}
                    data-testid='subscriberId'
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="g-2">
              <Col>
                <Form.Group
                  className="mb-3 left-input"
                  controlId="formBasicGroupID"
                >
                  <Form.Label>
                    <InfoOverlay label="Group ID" ireq={ireq} info={iGroupId} />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className={storedTheme !== 'dark' ? "input-width-small" : "input-width-small input_full_dark"}
                    onChange={(e) => handleChangeGroupId(e, dispatch)}
                    value={patient.groupId}
                    data-testid='groupId'
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPlanID">
                  <Form.Label>
                    <InfoOverlay label="Plan ID" ireq={ireq} info={iPlanId} />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className={storedTheme !== 'dark' ? "input-width-small" : "input-width-small input_full_dark"}
                    onChange={(e) => handleChangePlanId(e, dispatch)}
                    value={patient.planId}
                    data-testid='planId'
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="g-2 btns-down-plan">
              <Col>
                <Form.Group className="mb-3">
                  <Button
                    onClick={() => history.push("/pick_plans")}
                    className={storedTheme !== 'dark' ? "previous-planCover btn_blue" : "previous-planCover dark_btn_3"}
                    data-testid="previous_btn_coverage"
                  >
                    Previous{" "}
                  </Button>
                </Form.Group>
              </Col>
              <Col>
                <Button
                  type="submit"
                  className={storedTheme !== 'dark' ? "continue-btn-plan btn_blue" : "continue-btn-plan dark_btn_3"}
                  onClick={() => {
                    history.push("/get_clinical_data");
                  }}
                  data-testid="continue_btn_coverage"

                >
                  Continue
                </Button>
                {/* <Button
                  variant="primary"
                  type="submit"
                  className="previous-btn"
                  onClick={() => {
                    history.push("/pick_plans");
                    SetIsAuth(true);
                  }}
                >
                  Previous
                </Button> */}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <MobFooter />
    </Container>
  );
}

export default withRouter(PlanCoverage);
