import React, { useEffect, useState } from 'react'
import './ResourceSelectionModal.scss'
import { Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

//@ts-ignore
const DateSelectionModal = ({dataSelectModal,handleclose,handleDataProcessing,initialState}) => {  

  const [selectedValue, setSelectedValue] = React.useState('new');

  useEffect(() => {
    sessionStorage.setItem('importData',selectedValue)
  }, [selectedValue])
  

  return (
    <div>
        <Modal
        show={dataSelectModal}
        aria-labelledby="contained-modal-title-vcenter"
      centered
      size='lg'
      >
        {initialState.spinner?
        <div className='spinner_div'>
        <Spinner animation='border' variant='primary' style={{'marginLeft':'47%'}}/>
        <p style={{'textAlign':'center'}}>{initialState.loading_text}</p>
        </div>
        :
        <>
        {initialState.oneIdRecordStatus === true ? 
            <Modal.Body className='get_data_modal_body'>
              <Row>
                <Col style={{'margin':'1rem'}} xs={12} md={6}>
                  <Card className='data_select_card' onClick={()=>{setSelectedValue('new')}}>
                    <Card.Body className='data_select_card_body'>
                      {selectedValue === 'new' ? 
                    <span>
                    <CheckCircleRoundedIcon color="primary" fontSize='small'/>
                    </span>
                    :null}
                    <p className='card_header'>Get New Data</p>
                    
                    <p className='card_subcontent'>Import only new data based on
                    the Last Updated Date.
                    <br/>
                    <br/>
                    Data fetched on : <b>{initialState.data_fetched_text}</b>
                    </p>
                    </Card.Body>
                      <p style={{'textAlign':'right'}}><div className='recommend_badge'>Recommended</div></p>
                  </Card>
                </Col>
                <Col style={{'margin':'1rem'}} xs={12} md={5}>
                  <Card className='data_select_card' onClick={()=>{setSelectedValue('all')}}>
                    <Card.Body>
                    {selectedValue === 'all' ? 
                    <span>
                    <CheckCircleRoundedIcon color="primary" fontSize='small'/>
                    </span>
                    :null}
                  <p className='card_header'>Get All Data</p>
                  <p className='card_subcontent'>
                  Imports all your previous data.
                  </p>
                  </Card.Body>
                  </Card>
                </Col>
              </Row>
              <br/>
              <Button style={{'width':'100%','height':'3rem','fontSize':'1.2rem'}}
          onClick={()=>{handleDataProcessing();sessionStorage.setItem('importData',selectedValue);handleclose()}}
          >Continue</Button>
            </Modal.Body>
            :
            null
        }
            </>
}
          
        </Modal>
    </div>
  )
}

export default DateSelectionModal