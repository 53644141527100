import { History } from "history";
import { FormEvent } from "react";
import { getNewpayerlist, Postpayername } from "../../apis/apis";
import { appInsights } from "../../components/AppInsights/appInsights";
import { patientActions, PatientState } from "../../redux/feature/patientSlice";

//@ts-ignore
export const handleClick = (val, setSelectElement, setpayername, dispatch, payername, planName) => {
    setSelectElement(val);
    setpayername(payername);
    dispatch(patientActions.updateHealthPlanChoice(planName));
    appInsights.trackEvent({ name: `Payer ${payername} Selected.` })

};
//@ts-ignore
export const handleSubmit = async (e: FormEvent<HTMLFormElement>, selectElement: any, payername: string, setShowToast, setShow, setShowSpinner, patient) => {
    console.log("pat", payername);

    e.preventDefault();
    localStorage.setItem("patientData", JSON.stringify(patient));
    try {
        await getNewpayerlist(payername, "consent");
        setShowSpinner(false)
    }
    catch (error) {
        setShowToast(true)
        setShow(false)
    }
};
//@ts-ignore
export const handleStore = (patient: PatientState, setSpinnerTrue) => {
    console.log("store", patient);

    // setSpinnerTrue()
    localStorage.setItem("patientData", JSON.stringify(patient));
};

export const handleHistory = (history: string[] | History<unknown>) => {
    appInsights.trackEvent({ name: "Member selected payer not listed flow." })
    history.push("/plan_coverage");
};
//@ts-ignore
export const handlePostPayerPickPlans = async (clientName: any, setSpinner, setShowToast, setShow) => {
    try {
        const result = await getNewpayerlist(clientName, "consent");
        console.log(result);

        setSpinner(false)
        // setBtndisable(false)
    }
    catch (error) {
        setShowToast(true)
        setShow(false)
    }
}
