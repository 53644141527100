import { Link } from "react-router-dom"
import './SiteMap.scss'

const SiteMap = () => {
    let linkList = [
        {
            'name' : 'Contact Us',
            'ref' : "https://www.onyxhealth.io/contact/",
        },
        {
            'name' : 'About',
            'ref' : "https://www.onyxhealth.io/mmhd/",
        },
        {
            'name' : 'Developer Portal',
            'ref' : "https://portal.safhir.io/",
        },
        {
            'name' : 'Clarity',
            'ref' : "https://www.onyxhealth.io/clarity/",
        },
        {
            'name' : 'Enquiry',
            'ref' : "https://www.onyxhealth.io/contact/",
        },
    ]
    return (
        <div>
            <div className="main-container">
                <div className="page-info">
                    <h4 className="info-header">MMHD SiteMap</h4>
                    <h5 className="info-body">Know what you want and where to find it?<br />Get it here and you are on your way!</h5>
                </div>

                <div className="pages">
                    <div className="individual-container">
                        <hr className="line" style={{ color: '#ed3c0d' }} />
                        <h6 className="page-name">Home Page</h6>
                        <ul className="lists">
                            <li className="list"><Link to={"/dashboard"} className="list-a">Dashboard</Link></li>
                            {linkList.map((val,i)=>(
                            <li className="list" key={i}><a className="list-a" href={`${val.ref}`}>{val.name}</a></li>

                            ))}
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SiteMap