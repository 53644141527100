import React, { useState } from 'react'
import { Button, ButtonGroup, Modal, Spinner } from 'react-bootstrap';
import { handleOauth } from '../../../functions/LoginPage/LoginPageFuncs';
import { handlePostPayerPickPlans, handleStore } from '../../../functions/PickPlans/PickPlansFuncs';
import ErrorToast from '../../Toasts/ErrorToast';
import './ModalWaiting.css'
//@ts-ignore
const ModalWaiting = ({ show, setShow, handleClose, patientHealthPlanName, patient, showSpinner, setSpinnerTrue, showToast, setShowToast }) => {
  const storedTheme = sessionStorage.getItem("theme");

  const [disableDeny, setDisableDeny] = useState(false)

  const handleDenyBtn=()=>{
    setDisableDeny(true)
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className={storedTheme == 'dark' ? "modal3_dark" : "modal3_light"}
      >
        <Modal.Body className="pickplans_modal">
          By clicking <b>Allow</b>, you will be connected to {patientHealthPlanName}.
          You will need to sign in with the user ID and password
          you used with your prior health plan. This gives your
          permission to allow {process.env.REACT_APP_NAME} to access your health data.

          <br />
          <Button
            onClick={() => {handleOauth(setSpinnerTrue);  handleDenyBtn() }}
            // href={`${process.env.REACT_APP_OAUTH_NEW_PAYER}/auth/idp2`}
            rel="noreferrer"
            className={storedTheme != 'dark' ? "allow_btn btn_blue" : "allow_btn dark_btn_3"}
            data-testid="allow_pick_plans"
            disabled={disableDeny}
          >
            {showSpinner ?
              <Spinner variant='light' animation='border' />
              : <>Allow</>}
          </Button>
          <br />
          <Button
            variant="primary"
            className={storedTheme != 'dark' ? "deny_btn btn_blue" : "deny_btn dark_btn_3"}
            onClick={handleClose}
            disabled={disableDeny}
          >
            Deny
          </Button>
        </Modal.Body>
      </Modal>
      {showToast &&
        <ErrorToast show={showToast} setShowToast={setShowToast} />
      }
    </div>
  )
}

export default ModalWaiting
