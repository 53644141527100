//@ts-ignore
export const handleLogout = (history) => {
    localStorage.clear()
    sessionStorage.clear()
    history.push("/")
}

//@ts-ignore
export const GoToHome = (history) => {
    history.push("/")
}