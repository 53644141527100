import React from 'react';
import { Link } from 'react-router-dom';
import './DeskFooter.css'

import onyxlogo from "../../../images/Onyx.svg";

const DeskFooterLogin = () => {
    return <div className='login_footer_wrapper'>
        <div className="onyxDown">
            <p className="onyx-text">Powered By : </p>
            <img
                src={onyxlogo}
                alt="onyxLogo"
                className="onyx-icon"
                data-testid="onyxLogo"
            />
        </div>
        <p className='tplinks'>|<a href="https://www.onyxhealth.io/" target={"_blank"} className='TPlink'> About Us</a> | <Link to="/terms-of-service" className='TPlink'>Terms Of Service</Link> | <Link to="/privacy-policy" className='TPlink'>Privacy Policy</Link> |</p>
    </div>;
};

export default DeskFooterLogin;
