import React, { useCallback } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import parse from "html-react-parser"
import { Link } from 'react-router-dom';
import { handleCancel, handleOauth } from '../../../functions/LoginPage/LoginPageFuncs';
import { socketUrl } from '../../../websocket/websocket';
import useWebSocket, { ReadyState } from 'react-use-websocket';
//@ts-ignore
const ModalLogin1 = ({ show, handleClose, oauth_link, clientName, spinner, btndisable, showSpinnerTrue }) => {

  var terms = `${process.env.REACT_APP_TERMS_AND_CONDITIONS}`
  var client_name = `${clientName}`
  var replace_string = "{Client_Health_Plan}"

  var appName = `${process.env.REACT_APP_NAME}`
  var replace_appName = "{app_name}";

  var terms1 = terms.replaceAll(replace_string, client_name)
  var terms2 = terms1.replaceAll(replace_appName, appName)

  const clientLocal = sessionStorage.getItem("client")
  const storedTheme = sessionStorage.getItem("theme");
  let customer_uuid=sessionStorage.getItem("customer_uuid")
  const { sendMessage, lastMessage, readyState,sendJsonMessage } = useWebSocket(socketUrl);
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];
  const msg = {
    data: {
        initiation_time: Date.now(),
        end_time: Date.now(),
        customer_id: customer_uuid
    },
    msg_event: 'agree_terms'
}
const handleClickSendMessage = useCallback(() => sendJsonMessage(msg), []);
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-testid='modal'
        onClose={handleClose}
        className={storedTheme == 'dark' ? "modal3_dark" : "modal3_light"}
      >
        <Modal.Header><h3><b>Terms and Conditions</b></h3></Modal.Header>
        <Modal.Body className="modal-login">
          <div>{parse(terms2)}</div>
          <br />
          <p>Read our : <Link to="/terms-of-service">Terms of Service</Link> and <Link to="/privacy-policy">Privacy Policy</Link></p>

          <Button
            variant="primary"
            onClick={() => {handleClickSendMessage() ;handleOauth(showSpinnerTrue)  }}
            className={storedTheme != 'dark' ? "modal-btn btn_blue" : "modal-btn dark_btn_3"}
            data-testid='authorize_btn'
            disabled={btndisable}
          >
            {spinner ?
              <Spinner variant='light' animation='border' />
              :
              <>
                {parse(`I Authorize <b>${clientLocal}</b> to ${process.env.REACT_APP_NAME}`)}
              </>
            }
          </Button>

          <br />

          <Button
            variant="secondary"
            onClick={() => handleCancel(handleClose)}
            className="modal-btn"
            data-testid="btn_cancel"
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ModalLogin1
