import { Button, CardContent, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import { Form, Toast, ToastContainer } from "react-bootstrap";
import axios from 'axios';
import moment from 'moment';
import './HomePage.scss'

function ContactForm() {
    const [name, setName] = useState("");
    const [organization, setOrganization] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [purpose, setPurpose] = useState("");
    const [message, setMessage] = useState("");
    const [ip, setIp] = useState("");
    const pageUrl = window.location.href;

    const [showToast, setShowToast] = useState(false)

    const getIp = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIp(res.data.IPv4)
    }

    const formSubmitHandler = (e: any) => {
        e.preventDefault();

        setName('');
        setOrganization('');
        setEmail('');
        setPhone('');
        setMessage('');
        setPurpose('');
    };

    useEffect(() => {
        getIp()
    }, [ip])

    const handleChange = (event: SelectChangeEvent) => {
        setPurpose(event.target.value as string);
    };

    const sendMail = () => {


        const data = {
            "email_body": "<p><b>{{name}}</b> has requested a Demo/Sales/Enquiry through <b>MoveMyHealthData</b> website.<br>The senders details are given below.<br><br>Name: {{name}}<br>Organization: {{organization}}<br>Business Email: {{email}}<br>Phone: {{phone}}<br>Purpose: {{purpose}}<br>Message: {{message}}<br><br>Regards,MMHD<br><br><br>DISCLAIMER: This mail originated from <a href={{url}}>{{page}}</a> at {{time}} with user IP {{ip}}",

            "vars_dict": {
                "name": name,
                "organization": organization,
                "email": email,
                "phone": phone,
                "purpose": purpose,
                "message": message,
                "url": pageUrl,
                "page": pageUrl.split("://").pop(),
                "ip": ip,
                "time": moment().format('hh:mm:ss a')
            }
        };

        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'from': 'support@safhir.io',
                'to': `${process.env.REACT_APP_CONTACT_TO_MAIL}`,
                'subject': `${process.env.REACT_APP_CONTACT_MAIL_SUBJECT}`,
                'apikey': 'mmhd'
            },
            body: JSON.stringify(data)
        }

        let fetchRes = fetch("https://p2communicationapi.azurewebsites.net/mail/", options);
        fetchRes.then(res => res.json())
            .then(d => {
                console.log(d)
            })
    }

    const handleAlert=()=>{
        console.log("alerted");
        
        alert("Email sent successfully")    }
    return (
        <div className="contact-form-container">

            <CardContent>
                <Form method="POST" onSubmit={formSubmitHandler}>
                    <Grid container spacing={1}>
                        <Grid xs={12} item>
                            <TextField className="contact-text-field" label="Name" placeholder="Enter Name" variant="outlined" fullWidth required value={name} onChange={(e) => setName(e.target.value)} />
                        </Grid>

                        <Grid xs={12} item>
                            <TextField className="contact-text-field" label="Organization" placeholder="Enter Organization Name" variant="outlined" fullWidth required value={organization} onChange={(e) => setOrganization(e.target.value)} />
                        </Grid>

                        <Grid xs={12} item>
                            <TextField className="contact-text-field" type="email" label=" Business Email" placeholder="Enter Business Email" variant="outlined" fullWidth required value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Grid>

                        <Grid xs={12} item>
                            <TextField className="contact-text-field" type="numeric" label="Phone" placeholder="Enter Phone Name" variant="outlined" fullWidth value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </Grid>

                        <Grid xs={12} item>
                            <FormControl style={{ 'width': '100%' }}>
                                <InputLabel>Purpose</InputLabel>
                                <Select
                                    className="contact-text-field"
                                    labelId="select-purpose-label"
                                    id="select-purpose"
                                    value={purpose}
                                    label="Purpose"
                                    onChange={handleChange}
                                    required
                                >
                                    <MenuItem disabled selected value={""}>Select Purpose</MenuItem>
                                    <MenuItem value={"Schedule a demo"}>Schedule a demo</MenuItem>
                                    <MenuItem value={"Connect with sales"}>Connect with sales</MenuItem>
                                    <MenuItem value={"Get support"}>Get support</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid xs={12} item>
                            <TextField className="contact-text-field" label="Message" placeholder="Enter Message" variant="outlined" multiline rows={3} fullWidth required value={message} onChange={(e) => setMessage(e.target.value)} />
                        </Grid>

                        <Grid xs={12} item>
                            <Button type="submit" variant="contained" color="primary" fullWidth onClick={() => {
                                if (name !== "" && organization !== "" && email !== "" && message !== "") {
                                    sendMail();
                                    handleAlert();
                                }
                            }}>Submit</Button>
                        </Grid>
                    </Grid>
                </Form>
            </CardContent>
            <ToastContainer position='middle-center'>
                <Toast bg='success' className='toast_wrapper' show={showToast} delay={5000} autohide onClose={() => setShowToast(false)}>
                    {/* <div className='close_btn_toast'>
                        <CloseButton variant="white" onClick={() => setShowToast(false)} />
                    </div> */}
                    <Toast.Body>
                        <p className='toast_text'>Email sent Successfully</p>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}

export default ContactForm;