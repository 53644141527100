import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import "./PickPlans.css";
import { useHistory, withRouter } from "react-router";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import Logo from "../../components/Logo/Logo";
import ModalWaiting from "../../components/Modals/PickPlansModal/ModalWaiting";
import Bar1 from "../../components/SideBar/HorizontalStepper/Bar1";
import { appInsights } from "../../components/AppInsights/appInsights";
import MobFooter from "../../components/Footer/MobFooter/MobFooter";
import { handleClick, handleSubmit } from "../../functions/PickPlans/PickPlansFuncs";
import ErrorToast from "../../components/Toasts/ErrorToast";
import { getPayer, searchItems } from "../../functions/SearchFuncs/SearchFuncs";
import SideBar from "../../components/SideBar/OtherSideBar/SideBar";
import GridComponent from "../../components/PlansList/PlanList3";
import { Box, Grid } from "@mui/material";
import Switch from '@mui/material/Switch';
import CustomizedTreeViewPP from "../../components/TreeView/TreeViewPickPlans";
import { socketUrl } from "../../websocket/websocket";
import useWebSocket from "react-use-websocket";
import { initial_search_change } from "../../constants/PageChanges";
function PickPlans() {
  const [show, setShow] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [pickPlanList, setPickPlanList] = useState([])

  const [searchVar, setSearchVar] = useState(initial_search_change)

  var planlist;
  var obtlist;
  let cliList = [];
  let searchList: any = []

  const get_payer_list = () => {
    obtlist = (sessionStorage.getItem('plans_list'))
    console.log(obtlist);
    if (obtlist === null) {
      obtlist = sessionStorage.getItem('plans_list');
      planlist = []
    }
    else {
      obtlist = sessionStorage.getItem('plans_list');
      //@ts-ignore
      planlist = JSON.parse(obtlist);
      setPickPlanList(planlist)
      planlist.map((val: any, i: any) => {
        cliList.push(val.name)
        val.plans.map((k: any) => {
          cliList.push(k.legal_entity_name)
          cliList.push(k.contract_number)
          let logo: any[] = [{ card_img: `${k.logo}` }]
          searchList.push({ payername: `${val.name}`, planname: `${k.legal_entity_name}`, contract_number: `${k.contract_number}`, cardLogo: logo })
        })
      })
    }
  }
  const UI = useAppSelector((state) => state.UI);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const patient = useAppSelector((state) => state.patient);
  console.log(patient);
  const dispatch = useAppDispatch();
  const [payername, setpayername] = useState("");
  const route = useHistory();

  const [selectElement, setSelectElement] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [checked, setChecked] = React.useState(true);
  const [cardView, setCardView] = useState(false)
  const [cardViewText, setCardViewText] = useState("Payer View")

  let history = useHistory();

  const handleHistory = () => {
    appInsights.trackEvent({ name: "Member selected payer not listed flow." })
    history.push("/plan_coverage");
  };

  const items = getPayer();

  const setSpinnerTrueModal = () => { setShowSpinner(true) }

  console.log("planslist", searchList);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    // console.log(event.target.checked);
    if (event.target.checked === true) {
      setCardView(false)
      setCardViewText("Payer View")
    }
    else {
      setCardView(true)
      setCardViewText("Plan View")
    }
  };
  useEffect(() => {
    get_payer_list()
  }, [])
  

  const storedTheme = sessionStorage.getItem("theme");
  let customer_uuid=sessionStorage.getItem("customer_uuid")
  const {sendJsonMessage } = useWebSocket(socketUrl);

  //socketmsg
  const msg = {
    data: {
        initiation_time: new Date().toISOString(),
        end_time: new Date().toISOString(),
        customer_id: customer_uuid,
        status:1
    },
    msg_event: 'choosing_old_plan'
}
const handleClickSendMessage = useCallback(() => sendJsonMessage(msg), []);
  return (
    <Container fluid className={storedTheme !== 'dark' ? "page-wrapper" : "page-wrapper page-wrapper_dark"}>
      <Row className={storedTheme !== 'dark' ? "content-wrapper" : "content-wrapper page-wrapper_dark"}>
        <Col lg={4} className={storedTheme !== 'dark' ? "left-bar" : "left-bar left-bar_dark"}>
          <SideBar curr_path={1} msg_top="Welcome! first things first..." />
        </Col>
        <Col lg={8} xs={12} sm={12} className={storedTheme !== 'dark' ? "right-bar-pickPlans" : "right-bar-pickPlans right_bar_dark"}>
          <Logo />
          <div className="steps-wrapper-991">
            <Bar1 actstep={1} />
          </div>

          <Form
            className="form-complete"
            onSubmit={(e) => handleSubmit(e, selectElement, payername, setShowToast, setShow, setShowSpinner, patient)}
            data-testid="form"
          >
            <p className="step-info-991">
              How to get your Health Data
              <br />
            </p>
            <Form.Text className="text-muted">
              Select your prior health plan to access your health data
              <br />
              {/* <SearchBar setQuery={setQuery} setSearchparam={""} /> */}

              <Row>
                <Col className='search_move1' xs={5}>
                  {/* <SearchBar setQuery={setQuery} setSearchparam={setSearchparam} /> */}
                  <InputGroup className="mb-3 search_bar_pickplan">

                    <Form.Control
                      placeholder="Search here By Health Plan Name, Payer name or HNo.."
                      aria-label="payer"
                      aria-describedby="basic-addon1"
                      onChange={e => {
                        // setSearchInput(e.target.value)
                        // searchItems(e.target.value, setSearchInput, pickPlanList, setFilteredResults);
                        searchItems(e.target.value, setSearchInput, pickPlanList,setSearchVar,searchVar);
                      }}
                      className={storedTheme !== 'dark' ? "search_input_box" : "search_input_box search_input_box_dark"}
                      type='search' />

                  </InputGroup>

                </Col>
                <Col>
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }} /><span style={{ 'fontSize': '0.6rem' }}>{cardViewText}</span>
                </Col>
              </Row>

            </Form.Text>
            <div className="form_pickplans">
              <div className="button-choices" data-testid='plans_btn'>

                {cardView ? <>
                  {searchInput.length > 0 ? (
                    <>
                      <Box sx={{ width: '100%' }}>
                        <Grid container spacing={1} style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                          {searchVar.filteredResults.map((val: any, i: any) => (
                            val.plans.map((plan: any, i2: any) => (
                              <GridComponent key={i2} card_img={plan.logo} planName={plan.legal_entity_name} HNo={plan.contract_number} payer_name={val.name} handle_old_payer={() => handleClick(plan.contract_number, setSelectElement, setpayername, dispatch, val.name, plan.legal_entity_name)} selectElement={selectElement} oid={plan.contract_number} />

                            ))))}
                        </Grid>
                      </Box>
                    </>
                  ) :
                    <>
                      <Box sx={{ width: '100%' }}>
                        <Grid container spacing={1} style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                          {pickPlanList.map((val: any, i: any) => (
                            val.plans.map((plan: any, i2: any) => (
                              <GridComponent key={i2} card_img={plan.logo} planName={plan.legal_entity_name} HNo={plan.contract_number} payer_name={val.name} handle_old_payer={() => handleClick(plan.contract_number, setSelectElement, setpayername, dispatch, val.name, plan.legal_entity_name)} selectElement={selectElement} oid={plan.contract_number} />

                            ))
                          ))}
                        </Grid>
                      </Box>
                    </>

                  }

                </>
                  :
                  <>
                    {searchInput.length > 0 ? (
                      <>
                        <Box sx={{ width: '100%' }}>
                          <Grid container spacing={1} style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                            {searchVar.filteredResults.map((val: any, i: any) => (
                              <CustomizedTreeViewPP key={i} payer_name={val.name} plans={val.plans} selectElement={selectElement} oid={i} setSelectElement={setSelectElement} setpayername={setpayername} />
                            ))}
                          </Grid>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box sx={{ width: '100%' }}>
                          <Grid container spacing={1} style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                            {pickPlanList.map((val: any, i: any) => (
                              <CustomizedTreeViewPP key={i} payer_name={val.name} plans={val.plans} selectElement={selectElement} oid={i} setSelectElement={setSelectElement} setpayername={setpayername} />
                            ))}
                          </Grid>
                        </Box>

                      </>
                    )}
                  </>
                }

              </div>
            </div>
            <Row className="g-2 btns-down-pickPlans">
              <Col className="right-input">
                <Button
                  type="submit"
                  className={storedTheme !== 'dark' ? "continue-btn" : "continue-btn dark_btn_3"}
                  onClick={() => {
                    handleClickSendMessage()
                    handleShow();
                  }}
                  disabled={!selectElement}
                >
                  Continue
                </Button>
              </Col>
              <Col>
                <Button
                  className={storedTheme !== 'dark' ? "pair-button btn_blue_outline" : "pair-button dark_btn_outline_3"}
                  onClick={handleHistory}
                >
                  {" "}
                  My health plan is not listed
                </Button>
              </Col>
            </Row>

            <ModalWaiting
              show={show}
              setShow={setShow}
              handleClose={handleClose}
              patientHealthPlanName={payername}
              patient={patient}
              showSpinner={showSpinner}
              setSpinnerTrue={setSpinnerTrueModal}
              showToast={showToast}
              setShowToast={setShowToast}
            />
          </Form>
        </Col>
      </Row>
      <MobFooter />
      <ErrorToast show={showToast} setShowToast={setShowToast} />
    </Container>
  );
}

export default withRouter(PickPlans);
