import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../../pages/HomePage/HomePage.scss'
import mmhdLogo from '../../pages/HomePage/img/mmhd-logo.png'
import mmhdLogoDark from '../../pages/HomePage/img/mmhd-logo-dark.png'
import { ChangeEventHandler } from "react";
import MenuBarHome from '../../components/MenuBar/MenuBarHome';


const HomeHeader = () => {
    var logo = mmhdLogo;

    const setDark = () => {
        logo = mmhdLogoDark;
        sessionStorage.setItem("theme", "dark");
        document.documentElement.setAttribute("data-theme", "dark");
    };

    const setLight = () => {
        logo = mmhdLogo;
        sessionStorage.setItem("theme", "light");
        document.documentElement.setAttribute("data-theme", "light");
    };

    const storedTheme = sessionStorage.getItem("theme");

    const prefersDark =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: light)").matches;

    const defaultDark =
        storedTheme === "dark" || (storedTheme === null && prefersDark);

    if (defaultDark) {
        setDark();
    }

    const toggleTheme: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.checked) {
            setDark();
        } else {
            setLight();
        }
        window.location.reload()
    };
    let payers_list: any[] = []

    return (
        <div>
            <header id="header" className="header fixed-top">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                    <Link to={'/'}>
                        <a className="logo d-flex align-items-center">
                            <img src={logo} alt="" />
                        </a>
                    </Link>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
                            <li><a className="nav-link scrollto" href="https://www.onyxhealth.io/mmhd/" target="_blank" rel="noreferrer">About</a></li>
                            <li><a className="nav-link scrollto" href="https://portal.safhir.io/" target="_blank" rel="noreferrer">Developer Portal</a></li>
                            <li><a className="nav-link scrollto" href="#contact">Contact Us</a></li>
                            <li><Link to={'/dashboard'}><a className="getstarted scrollto" target="_blank" rel="noreferrer">Dashboard</a></Link></li>

                            <div className="toggle-theme-wrapper">
                                <span className='theme-span'>☀️</span>
                                <label className="toggle-theme" htmlFor="checkbox">
                                    <input className='theme-input'
                                        type="checkbox"
                                        id="checkbox"
                                        onChange={toggleTheme}
                                        defaultChecked={defaultDark}
                                    />
                                    <div className="slider round"></div>
                                </label>
                                <span className='theme-span'>🌒</span>
                            </div>
                        </ul>
                        <div className='mobile-nav-toggle'>
                            <MenuBarHome name={'Menu'} payers_list={payers_list} />
                        </div>
                    </nav>

                </div>
            </header>
        </div>
    );
}


export default HomeHeader;